import { FC, HTMLAttributes, useMemo, forwardRef, ReactNode, HTMLProps, ButtonHTMLAttributes } from "react";
import { Link } from "react-router-dom";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";

import { ItemsCount } from "@libs/components/UI/ItemsCount";
import { cx } from "@libs/utils/cx";
import { FloatingTooltip, FloatingTooltipProps } from "@libs/components/UI/FloatingTooltip";
import { Checkbox, CheckboxProps } from "@libs/components/UI/Checkbox";
import { ReactComponent as UpIcon } from "@libs/assets/icons/up-caret.svg";
import { ReactComponent as DownIcon } from "@libs/assets/icons/down-caret.svg";

const cxHeaderSizeClassName = {
  slim: "h-8",
  short: "h-10",
  medium: "h-12",
  tall: "h-14",
};

type HeaderSize = keyof typeof cxHeaderSizeClassName;

const cellVerticalPadding = {
  skinny: "py-1",
  slim: "py-2",
  default: "py-3",
};

type CelllVerticalPadding = keyof typeof cellVerticalPadding;

const cellHorizontalPadding = {
  none: "",
  default: "px-3 first:pl-5 last:pr-5",
};

type CellHorizontalPadding = keyof typeof cellHorizontalPadding;

type BorderColors = "border-slate-300" | "border-slate-200";
export const cxGridTableStyles = {
  cellPadding: (options?: { vertical?: CelllVerticalPadding; horizontal?: CellHorizontalPadding }) =>
    cx(
      cellHorizontalPadding[options?.horizontal ?? "default"],
      cellVerticalPadding[options?.vertical ?? "default"]
    ),
  cellPaddingFormField: "p-1 first:pl-5 last:pr-5",
  cell: (options?: { borderColor?: BorderColors; borderBottom?: boolean; borderTop?: boolean }) =>
    cx(
      "text-xs",
      options?.borderColor ?? "border-slate-300",
      (options?.borderBottom ?? true) && "border-b",
      (options?.borderTop ?? false) && "border-t"
    ),
  dataCell: "group-hover:bg-slate-50",
  headerFooterCell: (options: {
    bgColor?: string;
    size?: HeaderSize;
    sticky?: boolean;
    stickyTop?: string;
    stickyBottom?: string;
  }) =>
    cx(
      "flex items-center",
      options.bgColor ?? "bg-white",
      cxHeaderSizeClassName[options.size ?? "tall"],
      (options.sticky ?? true) && "sticky z-10",
      options.stickyTop ?? "top-0",
      options.stickyBottom ?? "bottom-0"
    ),
};

type CellStyleOptions = {
  border?: boolean;
  borderColor?: BorderColors;
  verticalPadding?: CelllVerticalPadding;
  hasPadding?: boolean;
  hideHover?: boolean;
};

const dataCellStyles = (options?: CellStyleOptions) => {
  const defaultClasses = [
    cxGridTableStyles.cell({ borderBottom: options?.border, borderColor: options?.borderColor }),
    !options?.hideHover && cxGridTableStyles.dataCell,
  ];

  if (options?.hasPadding === false) {
    return defaultClasses;
  }

  return [...defaultClasses, cxGridTableStyles.cellPadding({ vertical: options?.verticalPadding })];
};

export const EMPTY_CELL = "-";

type RowProps = {
  className?: string;
  children: React.ReactNode;
  isSelected?: boolean;
  highlightOnHover?: boolean;
} & HTMLProps<HTMLDivElement>;

export const Row = forwardRef<HTMLDivElement, RowProps>(
  ({ children, isSelected, className, highlightOnHover, ...props }, ref) => (
    <div
      className={cx(
        "contents",
        isSelected ? "*:bg-actionLight" : (highlightOnHover ?? true) && "group",
        className
      )}
      ref={ref}
      role={props.role ?? "row"}
      {...props}
    >
      {children}
    </div>
  )
);

const ActionsRowTheme: FC<{
  className?: string;
  includeBorder?: boolean;
  children?: ReactNode;
}> = ({ children, includeBorder = true, className }) => {
  return (
    <div className={cx("bg-slate-100", includeBorder && "border-b border-slate-300", className)}>
      {children}
    </div>
  );
};

const ActionsRowPosition: FC<{
  className?: string;
  children?: ReactNode;
}> = ({ children, className }) => {
  return <div className={cx("relative z-20", className)}>{children}</div>;
};

const ActionsRowContent: FC<{
  className?: string;
  children?: ReactNode;
}> = ({ children, className }) => {
  return <div className={cx("min-h-10 px-5 py-2", className)}>{children}</div>;
};

export const ActionsRow: FC<{
  contentClassName?: string;
  themeClassName?: string;
  includeBorder?: boolean;
  children?: ReactNode;
}> = ({ children, contentClassName, themeClassName, includeBorder = true }) => {
  return (
    <ActionsRowPosition>
      <ActionsRowTheme className={themeClassName} includeBorder={includeBorder}>
        <ActionsRowContent className={contentClassName}>{children}</ActionsRowContent>
      </ActionsRowTheme>
    </ActionsRowPosition>
  );
};

export const BulkActionsRow: FC<{
  contentClassName?: string;
  themeClassName?: string;
  includeBorder?: boolean;
  actions: ReactNode;
  selectedCount: number;
  total: number;
  singular: string;
  plural: string;
  filters?: ReactNode;
}> = ({
  contentClassName,
  themeClassName,
  includeBorder = true,
  actions,
  selectedCount,
  total,
  singular,
  plural,
  filters,
}) => {
  return (
    <ActionsRow
      contentClassName={contentClassName}
      themeClassName={themeClassName}
      includeBorder={includeBorder}
    >
      {actions}

      {selectedCount > 0 ? (
        <ItemsCount selectedCount={selectedCount} total={total} singular={singular} plural={plural} />
      ) : null}

      {selectedCount > 0 && filters ? <VerticalDivider size="sm" /> : null}

      {filters}
    </ActionsRow>
  );
};

export const InputsHeaderRow: FC<{ className?: string; children?: ReactNode }> = (props) => (
  <div {...props} className={cx("px-5 py-2 border-b border-slate-300", props.className)} />
);
export type CellProps = {
  className?: string;
  borderBottom?: boolean;
  borderTop?: boolean;
  borderColor?: BorderColors;
  children?: ReactNode;
};

export const Cell: FC<CellProps> = ({ children, className, borderBottom, borderTop, borderColor }) => {
  return (
    <div
      role="cell"
      className={cx(cxGridTableStyles.cell({ borderColor, borderBottom, borderTop }), className)}
    >
      {children}
    </div>
  );
};

export const TextCell: FC<{ className?: string } & CellStyleOptions & HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  border,
  verticalPadding,
  hasPadding,
  borderColor,
  ...rest
}) => {
  return (
    <div
      role="cell"
      className={cx(
        ...dataCellStyles({
          border,
          verticalPadding,
          hasPadding,
          borderColor,
        }),
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export const IconsCell: FC<{ className?: string; children?: ReactNode } & CellStyleOptions> = ({
  children,
  className,
  ...styleOptions
}) => {
  return (
    <div className={cx("flex items-start gap-x-2", ...dataCellStyles(styleOptions), className)}>
      {children}
    </div>
  );
};

export const InputCell: FC<{ className?: string; border?: boolean; children?: ReactNode }> = ({
  children,
  className,
  border,
}) => {
  return (
    <div
      className={cx(
        cxGridTableStyles.cellPaddingFormField,
        cxGridTableStyles.cell({ borderBottom: border }),
        className
      )}
    >
      {children}
    </div>
  );
};

export type ButtonCellProps = {
  truncate?: boolean;
} & CellStyleOptions &
  ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonCell = forwardRef<HTMLButtonElement, ButtonCellProps>(
  (
    { className, truncate = true, border, verticalPadding, hasPadding, hideHover, borderColor, ...props },
    ref
  ) => (
    <button
      {...props}
      type="button"
      className={cx(
        "text-left",
        truncate && "truncate",
        ...dataCellStyles({ border, borderColor, verticalPadding, hasPadding, hideHover }),
        className
      )}
      ref={ref}
    />
  )
);

export const LinkCell: FC<{ className?: string; to: string; children?: ReactNode } & CellStyleOptions> = ({
  children,
  className,
  to,
  ...styleOptions
}) => {
  return (
    <Link className={cx("flex items-center", ...dataCellStyles(styleOptions), className)} to={to}>
      {children}
    </Link>
  );
};

export type CheckboxCellProps = Omit<CheckboxProps, "value"> & {
  value: string | number;
  styleOptions?: CellStyleOptions;
};

export const CheckboxCell: FC<CheckboxCellProps> = ({ styleOptions, ...props }) => (
  <Checkbox {...props} className={cx("select-none", props.className, ...dataCellStyles(styleOptions))} />
);

export const HeaderCell: FC<{
  border?: boolean;
  className?: string;
  size?: HeaderSize;
  cellPadding?: { vertical?: CelllVerticalPadding; horizontal?: CellHorizontalPadding };
  sticky?: boolean;
  bgColor?: string;
  stickyTopClassName?: string;
  tooltip?: Omit<FloatingTooltipProps, "children">;
  children?: ReactNode;
  rounded?: boolean;
  borderColor?: BorderColors;
}> = ({
  border = true,
  size,
  stickyTopClassName,
  cellPadding,
  sticky,
  tooltip,
  bgColor,
  borderColor,
  rounded = false,
  ...props
}) => {
  const className = cx(
    "truncate font-sansSemiBold",
    rounded && "first:rounded-tl last:rounded-tr",
    cxGridTableStyles.headerFooterCell({ size, sticky, stickyTop: stickyTopClassName, bgColor }),
    cxGridTableStyles.cellPadding(cellPadding),
    props.className
  );

  return tooltip ? (
    <FloatingTooltip {...tooltip}>
      <div className={className}>
        <Cell borderBottom={border} borderTop={false} {...props} />
      </div>
    </FloatingTooltip>
  ) : (
    <Cell
      borderColor={borderColor}
      borderBottom={border}
      borderTop={false}
      {...props}
      className={className}
    />
  );
};

export const FooterCell: FC<{
  className?: string;
  size?: HeaderSize;
  bgColor?: string;
  sticky?: boolean;
  stickyBottomClassName?: string;
  borderBottom?: boolean;
  borderTop?: boolean;
  children?: ReactNode;
}> = ({ size, stickyBottomClassName, sticky, bgColor, borderBottom, borderTop, ...props }) => {
  const className = cx(
    "truncate font-sansSemiBold",
    cxGridTableStyles.headerFooterCell({ size, sticky, stickyBottom: stickyBottomClassName, bgColor }),
    cxGridTableStyles.cellPadding(),
    props.className
  );

  return (
    <Cell
      borderBottom={borderBottom ?? false}
      borderTop={borderTop ?? true}
      {...props}
      className={className}
    />
  );
};

export const HeaderButtonCell: FC<
  {
    size?: HeaderSize;
    sticky?: boolean;
    bgColor?: string;
    cellPadding?: { vertical?: CelllVerticalPadding; horizontal?: CellHorizontalPadding };
    borderColor?: BorderColors;
    stickyTopClassName?: string;
    tooltip?: Omit<FloatingTooltipProps, "children">;
  } & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ size, stickyTopClassName, sticky, tooltip, bgColor, borderColor, cellPadding, ...props }) => {
  const cellClassName = cx(
    "truncate font-sansSemiBold",
    cxGridTableStyles.cell({ borderColor }),
    cxGridTableStyles.headerFooterCell({ size, sticky, stickyTop: stickyTopClassName, bgColor }),
    cxGridTableStyles.cellPadding(cellPadding),
    props.className
  );

  return tooltip ? (
    <FloatingTooltip {...tooltip}>
      <button {...props} type="button" className={cellClassName} />
    </FloatingTooltip>
  ) : (
    <button {...props} type="button" className={cellClassName} />
  );
};

export const HeaderCheckboxCell = forwardRef<
  HTMLInputElement,
  Omit<CheckboxProps, "size"> & {
    onDeselectAllRows: Func;
    onSelectAllRows: Func;
    selectedCount: number;
    size?: HeaderSize;
    bgColor?: string;
    sticky?: boolean;
    stickyTopClassName?: string;
    tooltip?: Omit<FloatingTooltipProps, "children">;
    totalRows: number;
  }
>(
  (
    {
      className,
      onDeselectAllRows,
      onSelectAllRows,
      selectedCount,
      size,
      sticky,
      stickyTopClassName,
      tooltip,
      totalRows,
      bgColor,
      ...props
    },
    ref
  ) => {
    const cellClassName = cx(
      cxGridTableStyles.cell(),
      cxGridTableStyles.cellPadding(),
      cxGridTableStyles.headerFooterCell({ size, sticky, stickyTop: stickyTopClassName, bgColor }),
      className
    );

    const Control = () => (
      <Checkbox
        ref={ref}
        checked={selectedCount > 0}
        className={tooltip ? undefined : cellClassName}
        disabled={totalRows === 0}
        indeterminate={selectedCount > 0 && totalRows > selectedCount}
        onChange={() => (selectedCount === 0 ? onSelectAllRows() : onDeselectAllRows())}
        {...props}
      />
    );

    return tooltip ? (
      <FloatingTooltip {...tooltip}>
        <div className={cellClassName}>
          <Control />
        </div>
      </FloatingTooltip>
    ) : (
      <Control />
    );
  }
);

export const TableGrid: FC<{
  gridTemplateRows?: string;
  className?: string;
  columnWidths: string[];
  children?: ReactNode;
}> = ({ columnWidths, className, children, gridTemplateRows }) => {
  const gridTemplateColumns = useMemo(() => {
    return columnWidths.join(" ");
  }, [columnWidths]);

  return (
    <div style={{ gridTemplateColumns, gridTemplateRows }} className={cx("grid", className)}>
      {children}
    </div>
  );
};

export const Title: FC<{ className?: string; children?: ReactNode }> = ({ children, className }) => (
  <h1 className={cx("font-sansSemiBold", className)}>{children}</h1>
);

export const TitleContainer: FC<{ className?: string; children?: ReactNode }> = ({ className, children }) => (
  <div className={cx("pr-12", className)}>{children}</div>
);

export type SortDirection = "ASCENDING" | "DESCENDING";
// For use in table headers to indicate what sorting applies to column
export const ColumnSortIndicator: React.FC<{
  className?: string;
  direction?: SortDirection;
}> = ({ className, direction }) => {
  return (
    <div className={cx("flex flex-col", className)}>
      <UpIcon className={cx("h-2.5 w-2.5", direction === "ASCENDING" ? "text-greyDark" : "text-greyLight")} />
      <DownIcon
        className={cx("h-2.5 w-2.5", direction === "DESCENDING" ? "text-greyDark" : "text-greyLight")}
      />
    </div>
  );
};
