import { useState, useCallback } from "react";

export type ItemModal<T> =
  | {
      item: null;
      isOpen: false;
      isClosed: true;
      close: () => void;
      open: (newItem: T) => void;
    }
  | {
      item: T;
      isOpen: true;
      isClosed: false;
      close: () => void;
      open: (newItem: T) => void;
    };

const isOpen = <T>(item: T | null): item is T => item !== null;

export const useItemModal = <T>(initialItem: T | null): ItemModal<T> => {
  const [item, setItem] = useState<T | null>(initialItem);

  const open = useCallback((newItem: T) => {
    setItem(newItem);
  }, []);

  const close = useCallback(() => {
    setItem(null);
  }, []);

  return isOpen(item)
    ? {
        item,
        isOpen: true,
        isClosed: false,
        close,
        open,
      }
    : {
        item,
        isOpen: false,
        isClosed: true,
        close,
        open,
      };
};
