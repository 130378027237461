import { FC, useMemo } from "react";
import { Auth } from "aws-amplify";

import { cxStyles } from "@libs/components/UI/Button";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as GoogleIcon } from "@libs/assets/icons/google.svg";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { cx } from "@libs/utils/cx";

import { SignInErrorMessages } from "@libs/router/signOutReasons";
import { Banner } from "@libs/components/UI/Banner";
import { PageWrapper, PageWrapperCard } from "components/SignIn/PageWrapper";

import { useSignedOutAuthChannelListeners } from "components/Main/useSignedOutAuthChannelListeners";
import { useQueryParams } from "hooks/useQueryParams";

export const GoogleSignIn: FC = () => {
  const { query } = useQueryParams("signIn");

  useSignedOutAuthChannelListeners(query);
  usePageTitle("Administrator Login");

  const bannerErrorMessage = useMemo(
    () => (query.signOutReason ? SignInErrorMessages[query.signOutReason] : ""),
    [query.signOutReason]
  );

  const customState = useMemo(() => JSON.stringify({ returnUrl: query.returnUrl }), [query]);

  return (
    <PageWrapper>
      <PageWrapperCard size="sm">
        {bannerErrorMessage ? (
          <Banner className="text-sm mb-2 rounded" theme="error">
            {bannerErrorMessage}
          </Banner>
        ) : null}

        <div className="flex flex-col gap-y-6 w-[21rem]">
          <div className="flex flex-col gap-y-2">
            <h2 className="font-sansSemiBold text-sm">Administrator Login</h2>
            <span className="text-xs">Welcome to the Archy administrator login page.</span>
          </div>

          <button
            className={cx(
              `flex
               items-center
               justify-center
               gap-x-1
               bg-white
               border
               border-slate-200
               hover:bg-slate-100
               hover:text-archyBlue-700`,
              cxStyles.container,
              cxStyles.sizes.large
            )}
            onClick={() => Auth.federatedSignIn({ customProvider: "archy-support", customState })}
            type="button"
          >
            <Icon SvgIcon={GoogleIcon} />
            Sign in with Google
          </button>
        </div>
      </PageWrapperCard>
    </PageWrapper>
  );
};
