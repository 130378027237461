import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Pill, PillSize } from "@libs/components/UI/Pill";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as CloseFilledIcon } from "@libs/assets/icons/cancel-circle-filled.svg";

export const RemovablePill: React.FC<{
  label: string;
  onRemove: Func;
  disabled?: boolean;
  size?: PillSize;
  version: "v1" | "v2";
}> = ({ label, onRemove, disabled = false, size = "md", version }) => {
  return version === "v2" ? (
    <div className="group">
      <Pill
        className="relative flex items-center"
        theme={disabled ? "disabled" : "whiteGreyLighter"}
        size={size}
      >
        <div title={label} className="truncate max-w-xs">
          {label}
        </div>
        {disabled ? null : (
          <ButtonIcon
            className={`
              absolute
              -top-1.5
              -right-1.5
              hidden
              group-hover:flex
              items-center
              text-primaryTheme
            `}
            SvgIcon={CloseFilledIcon}
            onClick={onRemove}
            theme="primary"
            size="lg"
          />
        )}
      </Pill>
    </div>
  ) : (
    <Pill theme="whiteGreyLighter" className="flex items-center">
      <div title={label} className="truncate max-w-xs">
        {label}
      </div>
      <button className="flex items-center ml-2.5 text-primaryTheme" onClick={onRemove} type="button">
        <CloseIcon className="h-2.5 w-2.5" />
      </button>
    </Pill>
  );
};
