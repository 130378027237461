import React, { forwardRef } from "react";
import { Link, LinkProps } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { cxStyles, ButtonProps } from "./Button";

export type ButtonLinkProps = Omit<ButtonProps, "inactive"> & React.AnchorHTMLAttributes<HTMLAnchorElement>;
export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  ({ className, children, theme = "primary", size = "large", ...rest }, ref) => {
    return (
      <a
        {...rest}
        ref={ref}
        className={cx(
          cxStyles.container,
          cxStyles.themes[theme]({ disabled: false }),
          theme !== "link" && cxStyles.sizes[size],
          className
        )}
      >
        {children}
      </a>
    );
  }
);

export const ButtonInternalLink = forwardRef<
  HTMLAnchorElement,
  Omit<ButtonProps, "inactive"> & LinkProps & { disabled?: boolean }
>(({ className, children, to, theme = "primary", size = "large", disabled, ...rest }, ref) => {
  const contentClassName = cx(
    cxStyles.container,
    cxStyles.themes[theme]({ disabled: Boolean(disabled) }),
    theme !== "link" && cxStyles.sizes[size],
    className
  );

  return (
    <Link
      {...rest}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
        }

        rest.onClick?.(e);
      }}
      to={to}
      ref={ref}
      className={contentClassName}
    >
      {children}
    </Link>
  );
});
