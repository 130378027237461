import { captureMessage as sentryCaptureMessage } from "@sentry/react";
import { HttpResponse } from "@libs/api/generated-api";
import { hasErrorOnResponse } from "@libs/utils/hasErrorOnResponse";
import { isHttpResponseError } from "@libs/utils/isHttpResponseError";

/**
 * Use this in a catch statement to convert the error to a typed HttpResponse.
 * @param error The error to check.
 * @returns True if its an HttpResponse error that could not parse the error JSON and has an Error instance instead, otherwise false.
 */
export const isResponseParseError = (error: unknown): error is HttpResponse<unknown, Error> => {
  return hasErrorOnResponse(error) && !isHttpResponseError(error);
};

export const handleResponseParseError = (response: HttpResponse<unknown, Error>) => {
  const { error, url } = response;

  sentryCaptureMessage(`HTTP ${response.status} Error`, {
    level: "error",
    extra: {
      status: response.status,
      name: error.name,
      message: error.message,
      url,
    },
  });
};
