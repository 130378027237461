import { FC, useState, useMemo } from "react";
import { Link } from "react-router-dom";

import { PracticeInfoVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { getPagingDetails } from "@libs/utils/queries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooterButtons } from "@libs/components/UI/ModalComponents";
import { useSearchKeyboardNavigation } from "@libs/hooks/useSearchModal";

import { CommandPaletteSearchInput } from "@libs/components/UI/CommandPaletteSearchInput";

import { paths } from "utils/routing/paths";
import { searchForPractices } from "api/user/queries";
import { SearchPracticesResult } from "components/Main/SearchPracticesResult";
import { SearchPracticesNoResults } from "components/Main/SearchPracticesNoResults";

export const SearchPracticesModal: FC<{
  onRequestClose: Func;
  onClickPractice?: (patient: PracticeInfoVO) => void;
}> = ({ onRequestClose, onClickPractice }) => {
  const [searchString, setSearchString] = useState("");
  const { search: debouncedSearchString } = useDebouncedSearch(searchString);
  const { searchResultsRef, highlightedIndex, handleMouseEnterResult, handleKeyDownSearch } =
    useSearchKeyboardNavigation(debouncedSearchString);

  const [searchPracticesQuery] = useApiQueries([
    searchForPractices({
      args: {
        searchString: debouncedSearchString,
        pageSize: 10,
        pageNumber: 1,
      },
      queryOptions: { enabled: debouncedSearchString.length > 0 },
    }),
  ]);

  const hasMoreResults = useMemo(() => {
    const totalPages = getPagingDetails(searchPracticesQuery)?.totalPages;

    return Boolean(totalPages && totalPages > 1);
  }, [searchPracticesQuery]);

  return (
    <Modal centerVertically={false} onClickOutside={onRequestClose} onClose={onRequestClose} size="xs">
      <div className="pt-3 px-3 pb-2 rounded-t">
        <CommandPaletteSearchInput
          searchString={searchString}
          onChangeSearch={setSearchString}
          onKeyDownSearch={handleKeyDownSearch}
          isSearching={searchPracticesQuery.isLoading}
          placeholder="Search Practices"
        />
      </div>

      <ModalContent className="px-3 pb-3">
        <QueryResult
          queries={[searchPracticesQuery]}
          loading={
            <div className="h-[3.875rem]">
              <LoadingContent />
            </div>
          }
        >
          {searchPracticesQuery.data ? (
            searchPracticesQuery.data.length > 0 ? (
              <div ref={searchResultsRef}>
                {searchPracticesQuery.data.map((practice, index) => (
                  <SearchPracticesResult
                    key={practice.id}
                    practice={practice}
                    onLinkToPractice={onRequestClose}
                    onMouseEnterResult={() => handleMouseEnterResult(index)}
                    isHighlighted={index === highlightedIndex}
                    onClick={onClickPractice}
                  />
                ))}
              </div>
            ) : (
              <SearchPracticesNoResults />
            )
          ) : null}
        </QueryResult>
      </ModalContent>

      {searchPracticesQuery.data && hasMoreResults ? (
        <div className="border-t border-t-slate-200 px-3 py-2">
          <ModalFooterButtons>
            <Link
              className="font-sansSemiBold text-xs text-archyBlue-500"
              to={paths.practicesTab({ search: searchString })}
              onClick={onRequestClose}
            >
              See all results
            </Link>
          </ModalFooterButtons>
        </div>
      ) : null}
    </Modal>
  );
};
