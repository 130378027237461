import { MainContent } from "@libs/components/UI/MainContent";
import { Panel } from "@libs/components/UI/Panel";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { Outlet } from "react-router-dom";
import { paths } from "utils/routing/paths";
import { useQueryParams } from "hooks/useQueryParams";

export const CreatePracticeLayout: React.FC = () => {
  const { query } = useQueryParams("createPractice");

  return (
    <MainContent>
      <Panel
        actions={
          <LinkIcon
            SvgIcon={CancelIcon}
            to={query.from ?? paths.practicesTab()}
            theme="primary"
            tooltip={{ content: "Cancel", theme: "SMALL" }}
          />
        }
        className="h-full flex-1"
        contentClassName="overflow-y-scroll"
        includePadding={false}
        title="Create Practice"
      >
        <Outlet />
      </Panel>
    </MainContent>
  );
};
