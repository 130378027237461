import EmptySearch from "@libs/assets/images/empty-search.svg";

export const SearchPracticesNoResults: React.FC = () => (
  <div className="flex flex-col items-center gap-y-2 pb-12">
    <img src={EmptySearch} alt="No Search Results" />
    <div className="text-sm text-slate-900 text-center">
      <p className="font-sansSemiBold">We couldn&apos;t find anything</p>
    </div>
  </div>
);
