import { FC, useMemo } from "react";
import { ReactComponent as BuildingIcon } from "@libs/assets/icons/building.svg";
import { Link, matchPath, useLocation } from "react-router-dom";
import { ReactComponent as ArchyLogo } from "@libs/assets/components/logo-archy.svg";
import { useSearchModal } from "@libs/hooks/useSearchModal";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { ReactComponent as PlusIcon } from "@libs/assets/icons/plus.svg";
import { getFullUrl } from "@libs/utils/location";
import { paths, routesConfig } from "utils/routing/paths";
import { PracticeNavIcon } from "components/Main/AdminNavIcon";
import { AdminProfileMenu } from "components/Main/AdminProfileMenu";
import { useAdminAccount } from "contexts/AdminAccountContext";
import { SearchPracticesModal } from "components/Main/SearchPracticesModal";

export const Navigation: FC = () => {
  const { pathname } = useLocation();
  const { searchModal: searchPracticesModal, searchShortcut } = useSearchModal();
  const admin = useAdminAccount();

  const name = useMemo(() => `${admin.firstName} ${admin.lastName}`, [admin.firstName, admin.lastName]);
  const location = useLocation();

  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <div>
          <Link to={paths.practicesTab()}>
            <ArchyLogo className="size-8 text-archyBlue-500" />
          </Link>
          <div className="mt-20 flex flex-col gap-y-2">
            <Link to={paths.createPractice({ from: getFullUrl(location) })}>
              <PracticeNavIcon
                tooltip={{ content: "Create Practice", theme: "SMALL" }}
                Icon={PlusIcon}
                selected={Boolean(
                  matchPath({ path: routesConfig.createPractice.path, end: false }, pathname)
                )}
              />
            </Link>

            <button type="button" onClick={searchPracticesModal.on}>
              <PracticeNavIcon
                tooltip={{ content: `Search Practices (${searchShortcut})`, theme: "SMALL" }}
                Icon={SearchIcon}
                selected={searchPracticesModal.isOn}
              />
            </button>
          </div>
          <div className="mt-8 flex flex-col gap-y-2">
            <Link to={routesConfig.practicesTab.path}>
              <PracticeNavIcon
                tooltip={{ content: "Practices", theme: "SMALL" }}
                Icon={BuildingIcon}
                selected={Boolean(
                  matchPath({ path: routesConfig.practicesTab.path, end: false }, pathname) &&
                    pathname !== routesConfig.createPractice.path
                )}
              />
            </Link>
          </div>
        </div>
        <div>
          <AdminProfileMenu name={name} />
        </div>
      </div>
      {searchPracticesModal.isOn && <SearchPracticesModal onRequestClose={searchPracticesModal.off} />}
    </>
  );
};
