import { useCallback } from "react";
import { useQueryParams } from "hooks/useQueryParams";

export const useEditPracticeSettings = () => {
  const { query, updateQuery } = useQueryParams("practice");
  const { edit } = query;
  const handleEditingChanged = useCallback(
    (editing: boolean) => {
      updateQuery("replaceIn", { edit: editing });
    },
    [updateQuery]
  );

  return {
    isEditing: Boolean(edit),
    handleEditingChanged,
  };
};
