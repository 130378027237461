import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Hub } from "aws-amplify";
import { HubCallback } from "@aws-amplify/core";

import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useStorageContext } from "@libs/contexts/StorageContext";

import { useAdminActivityStorage } from "storage/activity";
import { postAuthChannelMessage } from "hooks/useAuthChannelListeners";
import { paths } from "utils/routing/paths";

export const OAuthSignIn: FC = () => {
  const navigate = useNavigate();
  const storage = useStorageContext();
  const activityStorage = useAdminActivityStorage();

  useEffect(() => {
    const onMessage: HubCallback = (listener) => {
      if (listener.payload.event !== "customOAuthState") {
        return;
      }

      activityStorage.setRecentlyActive();
      postAuthChannelMessage({ type: "signIn" });
      navigate(paths.home());
    };

    return Hub.listen("auth", onMessage);
  }, [storage.localStorage, activityStorage, navigate]);

  return <LoadingOverlaySpinner loadingText="Signing In" centerVertically />;
};
