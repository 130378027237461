import { FC, useMemo } from "react";

import { useApiQueries } from "@libs/hooks/useApiQueries";

import { QueryResult } from "@libs/components/UI/QueryResult";

import { InputsHeaderRow, Title, TitleContainer } from "@libs/components/UI/GridTableComponents";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Divider } from "@libs/components/UI/Divider";
import Skeleton from "react-loading-skeleton";
import { Form } from "@libs/components/UI/Form";
import { getPracticeConnectedRemoteSettings, getPracticeRemoteSettings } from "api/practice/queries";
import { useAdminAccount } from "contexts/AdminAccountContext";
import { usePathParams } from "hooks/usePathParams";
import { AdminPanel } from "components/UI/AdminPanel";

const getSecureCellValue = (hasSetValue?: boolean) => (hasSetValue ? "•••••••" : undefined);

export const ServerAccessRoute: FC = () => {
  const { practiceId } = usePathParams("practice");
  const { isAdminPortalSuperuser } = useAdminAccount();

  const [practiceConnectedRemoteSettingsQuery, superuserRemoteSettingsQuery] = useApiQueries([
    getPracticeConnectedRemoteSettings({
      args: { practiceId },
      queryOptions: { enabled: !isAdminPortalSuperuser },
    }),

    // This api only works for super users
    getPracticeRemoteSettings({ args: { practiceId }, queryOptions: { enabled: isAdminPortalSuperuser } }),
  ]);

  const secureDetails = useMemo(() => {
    return isAdminPortalSuperuser
      ? {
          serverUsername: superuserRemoteSettingsQuery.data?.serverUsername,
          serverPassword: superuserRemoteSettingsQuery.data?.serverPassword,
          pmsUsername: superuserRemoteSettingsQuery.data?.pmsUsername,
          pmsPassword: superuserRemoteSettingsQuery.data?.pmsPassword,
          anydeskAddress: superuserRemoteSettingsQuery.data?.anydeskAddress,
          anydeskAddress2: superuserRemoteSettingsQuery.data?.anydeskAddress2,
          anydeskAddress3: superuserRemoteSettingsQuery.data?.anydeskAddress3,
        }
      : {
          serverUsername: getSecureCellValue(practiceConnectedRemoteSettingsQuery.data?.hasServerUsername),
          serverPassword: getSecureCellValue(practiceConnectedRemoteSettingsQuery.data?.hasServerPassword),
          pmsUsername: getSecureCellValue(practiceConnectedRemoteSettingsQuery.data?.hasPmsUsername),
          pmsPassword: getSecureCellValue(practiceConnectedRemoteSettingsQuery.data?.hasPmsPassword),
          anydeskAddress: practiceConnectedRemoteSettingsQuery.data?.anydeskAddress,
          anydeskAddress2: practiceConnectedRemoteSettingsQuery.data?.anydeskAddress2,
          anydeskAddress3: practiceConnectedRemoteSettingsQuery.data?.anydeskAddress3,
        };
  }, [
    isAdminPortalSuperuser,
    practiceConnectedRemoteSettingsQuery.data?.anydeskAddress,
    practiceConnectedRemoteSettingsQuery.data?.anydeskAddress2,
    practiceConnectedRemoteSettingsQuery.data?.anydeskAddress3,
    practiceConnectedRemoteSettingsQuery.data?.hasPmsPassword,
    practiceConnectedRemoteSettingsQuery.data?.hasPmsUsername,
    practiceConnectedRemoteSettingsQuery.data?.hasServerPassword,
    practiceConnectedRemoteSettingsQuery.data?.hasServerUsername,
    superuserRemoteSettingsQuery.data?.anydeskAddress,
    superuserRemoteSettingsQuery.data?.anydeskAddress2,
    superuserRemoteSettingsQuery.data?.anydeskAddress3,
    superuserRemoteSettingsQuery.data?.pmsPassword,
    superuserRemoteSettingsQuery.data?.pmsUsername,
    superuserRemoteSettingsQuery.data?.serverPassword,
    superuserRemoteSettingsQuery.data?.serverUsername,
  ]);

  return (
    <>
      <InputsHeaderRow className="flex justify-between">
        <TitleContainer className="flex flex-row justify-between">
          <Title>Server Access</Title>
        </TitleContainer>
      </InputsHeaderRow>

      <div className="flex flex-col h-full min-h-0">
        <AdminPanel>
          <QueryResult
            queries={[practiceConnectedRemoteSettingsQuery, superuserRemoteSettingsQuery]}
            loading={<Skeleton className="h-64" />}
          >
            <Form className="flex flex-col gap-6">
              <h2 className="font-sansSemiBold text-sm">AnyDesk ID</h2>
              <div className="grid grid-cols-2 gap-6">
                <FormFieldInput label="AnyDesk ID 1" value={secureDetails.anydeskAddress} edit={false} />
                <FormFieldInput label="AnyDesk ID 2" value={secureDetails.anydeskAddress2} edit={false} />
                <FormFieldInput label="AnyDesk ID 3" value={secureDetails.anydeskAddress3} edit={false} />
              </div>

              <Divider className="border-dashed" />

              <h2 className="font-sansSemiBold text-sm">Access Information</h2>

              <div className="grid grid-cols-2 gap-6">
                <FormFieldInput label="Server Username" value={secureDetails.serverUsername} edit={false} />
                <FormFieldInput label="Server Password" value={secureDetails.serverPassword} edit={false} />
                <FormFieldInput label="PMS Username" value={secureDetails.pmsUsername} edit={false} />
                <FormFieldInput label="PMS Password" value={secureDetails.pmsPassword} edit={false} />
              </div>
            </Form>
          </QueryResult>
        </AdminPanel>
      </div>
    </>
  );
};
