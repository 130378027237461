import React from "react";
import { useNavigate } from "react-router-dom";
import { paths } from "utils/routing/paths";
import { usePathParams } from "hooks/usePathParams";
import { PracticeAdminsForm } from "components/Practice/forms/PracticeAdminsForm";

export const SetupPracticeAdminsRoute: React.FC = () => {
  const { practiceId } = usePathParams("setupPracticeAdmins");
  const navigate = useNavigate();

  return (
    <PracticeAdminsForm
      practiceId={practiceId}
      onSuccess={() => {
        navigate(paths.setupPracticeFeatures({ practiceId }));
      }}
    />
  );
};
