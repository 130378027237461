import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { paths } from "utils/routing/paths";
import { useAuthChannelListeners } from "hooks/useAuthChannelListeners";

export const useSignedOutAuthChannelListeners = (options?: { returnUrl?: string; lastUserId?: number }) => {
  const navigate = useNavigate();

  const authChannelEvents = useMemo(
    () => ({
      onSignIn: () => {
        navigate(options?.returnUrl ?? paths.home());
      },
    }),
    [navigate, options?.returnUrl]
  );

  useAuthChannelListeners(authChannelEvents);
};
