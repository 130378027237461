import { toast } from "react-toastify";
import { isHttpResponseError } from "@libs/utils/isHttpResponseError";
import { handleErrorResponse, isErrorResponse } from "@libs/utils/isErrorResponse";
import { handleResponseParseError, isResponseParseError } from "@libs/utils/isResponseParseError";
import { handleCodeError, isCodeError } from "@libs/utils/isCodeError";

export const CANNED_ERROR_MESSAGE = "Something went wrong, please try again.";

export const handleError = (error: unknown) => {
  if (isHttpResponseError(error)) {
    const errors = handleErrorResponse(error.error, error.url);

    errors.forEach((msg) => toast.error(msg));
  } else if (isResponseParseError(error)) {
    toast.error(CANNED_ERROR_MESSAGE);
    handleResponseParseError(error);
  } else if (isErrorResponse(error)) {
    const errors = handleErrorResponse(error);

    errors.forEach((msg) => toast.error(msg));
  } else if (isCodeError(error)) {
    toast.error(CANNED_ERROR_MESSAGE);
    handleCodeError(error);
  }
};
