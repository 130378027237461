import { ComponentProps, FC, PropsWithChildren, ReactNode, useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { isNumber } from "@libs/utils/types";
import { pluralize } from "@libs/utils/pluralize";
import { Button } from "@libs/components/UI/Button";
import { Pill, PillSize } from "@libs/components/UI/Pill";
import { formatNumberWithCommas } from "@libs/utils/formatNumber";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { RemovablePill } from "@libs/components/UI/RemovablePill";
import { FilterComponentProps, FilterInput } from "@libs/components/UI/queryFilterPillsUtils";

const ClearAllFiltersPill: FC<
  PropsWithChildren<{
    label?: ReactNode;
    onClick: Func;
    disabled?: boolean;
    size?: PillSize;
    version: "v1" | "v2";
  }>
> = ({ children, label, onClick, disabled = false, size = "md", version }) => {
  if (disabled) {
    return null;
  }

  return version === "v2" ? (
    <Pill theme="slate200" size={size}>
      <div className="flex items-center gap-x-1">
        {label}
        <Button className="block text-xs" theme="link" onClick={onClick}>
          {children}
        </Button>
      </div>
    </Pill>
  ) : (
    <Pill theme="whiteGreyLighter">
      <div className="flex items-center gap-x-2">
        {label}
        <Button className="block text-xs" theme="link" onClick={onClick}>
          {children}
        </Button>
      </div>
    </Pill>
  );
};

interface Props<T extends FilterInput> {
  clearAllText?: ReactNode;
  dataTestId?: string;
  disabled?: boolean;
  emptyParams: Partial<T>;
  filters: FilterComponentProps<T>[];
  numResults?: number | undefined;
  onClearAll?: (updates: Partial<T>) => void;
  onUpdateParams: (updates: Partial<T>) => void;
  params: T;
  queries?: ComponentProps<typeof QueryResult>["queries"];
  size?: PillSize;
  // Temporary flag to move all filters over to new design
  version?: "v1" | "v2";
}

export const QueryFilterPillsContainer: FC<PropsWithChildren<{ dataTestId?: string }>> = ({
  dataTestId,
  children,
}) => (
  <div className="flex gap-2 flex-wrap" data-testid={dataTestId}>
    {children}
  </div>
);

export const QueryFilterPillItems = <T extends FilterInput>({
  clearAllText,
  disabled = false,
  emptyParams,
  filters,
  numResults,
  onClearAll,
  onUpdateParams,
  params,
  queries,
  size = "md",
  version = "v1",
}: Props<T>) => {
  const handleClearAll = useCallback(() => {
    onClearAll?.(emptyParams);
  }, [emptyParams, onClearAll]);

  return (
    <>
      {version === "v1" && onClearAll ? (
        <ClearAllFiltersPill
          label={
            <div>
              Filtered results{numResults === undefined ? "" : `: ${formatNumberWithCommas(numResults)}`}
            </div>
          }
          onClick={handleClearAll}
          version={version}
        >
          {clearAllText ?? "Clear all"}
        </ClearAllFiltersPill>
      ) : null}
      {filters.map((filter) => (
        <RemovablePill
          key={filter.id}
          label={filter.label}
          onRemove={() => {
            if ("value" in filter) {
              const list = params[filter.prop] as unknown[];
              const newList = list.filter((val) => val !== filter.value);

              onUpdateParams({ [filter.prop]: newList.length ? newList : undefined } as Partial<T>);
            } else if ("props" in filter) {
              const updates: Partial<T> = {};

              for (const prop of filter.props) {
                updates[prop] = undefined;
              }
              onUpdateParams(updates);
            } else {
              onUpdateParams({ [filter.prop]: undefined } as Partial<T>);
            }
          }}
          disabled={disabled}
          size={size}
          version={version}
        />
      ))}
      {version === "v2" && onClearAll ? (
        <ClearAllFiltersPill
          label={
            <QueryResult loading={<Skeleton className="w-14" />} queries={queries}>
              {isNumber(numResults) &&
                `${formatNumberWithCommas(numResults)} ${pluralize(numResults, "Result", "results")}`}
            </QueryResult>
          }
          onClick={handleClearAll}
          disabled={disabled}
          size={size}
          version={version}
        >
          {filters.length ? clearAllText ?? "Clear" : ""}
        </ClearAllFiltersPill>
      ) : null}
    </>
  );
};

export const QueryFilterPills = <T extends FilterInput>({ dataTestId, ...props }: Props<T>) => {
  return (
    <QueryFilterPillsContainer dataTestId={dataTestId}>
      <QueryFilterPillItems {...props} />
    </QueryFilterPillsContainer>
  );
};
