import React from "react";
import { NavLink } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { BoldSpaced } from "./BoldSpaced";

export const SideNavigationTab: React.FC<{ to: string; children: string; replace?: boolean }> = ({
  to,
  children,
  replace,
}) => {
  return (
    <NavLink
      className={({ isActive }) =>
        cx(
          `p-3
           block
           hover:bg-slate-200
           text-sm
           font-sansSemiBold
           rounded`,
          isActive && "text-primaryTheme bg-archyBlue-50"
        )
      }
      replace={replace}
      to={to}
    >
      <BoldSpaced>{children}</BoldSpaced>
    </NavLink>
  );
};
