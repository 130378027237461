import {
  BoolVal,
  compileRoutesConfig,
  Enum,
  NumVal,
  ParsedParams,
  routesConfigHelper,
  StrVal,
} from "@libs/router/url";
import { AuthRoutesConfig } from "utils/routing/auth";

const PracticesTabQueryParams = {
  search: StrVal,
};

export type PracticeTab = "info" | "features" | "server-access" | "migration-settings" | "employees";
export type PracticeCreationStep = "administrators" | "features";
export const PracticeParams = {
  practiceId: NumVal,
  tab: Enum<PracticeTab>().Val(["info", "features", "server-access", "migration-settings", "employees"]),
};

export const routesConfig = routesConfigHelper({
  ...AuthRoutesConfig,
  home: {
    path: "/",
  },
  practicesTab: {
    path: "/practices",
    query: {
      search: StrVal,
    },
  },
  practice: {
    path: "/practices/:practiceId/:tab",
    params: PracticeParams,
    query: {
      edit: BoolVal,
    },
  },
  createPractice: {
    path: "/create-practice",
    query: {
      from: StrVal,
    },
  },
  setupPracticeAdmins: {
    path: "/setup-practice/:practiceId/administrators",
    params: {
      practiceId: NumVal,
    },
    query: {
      from: StrVal,
    },
  },
  setupPracticeFeatures: {
    path: "/setup-practice/:practiceId/features",
    params: {
      practiceId: NumVal,
    },
    query: {
      from: StrVal,
    },
  },
});

export type RoutesConfig = typeof routesConfig;

export const paths = compileRoutesConfig(routesConfig);
export const signedInRedirect = routesConfig.home.path;
export const getRedirectToSignedInUrl = (returnUrl?: string | null) => {
  return returnUrl ?? signedInRedirect;
};
export type PracticesTabQueryParams = ParsedParams<typeof PracticesTabQueryParams>;
