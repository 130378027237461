import { useMemo, FC } from "react";
import { LoaderFunctionArgs, redirect, redirectDocument } from "react-router-dom";

import { getFullUrl } from "@libs/utils/location";
import { getTokensForApi, getTokensForAuthCheck } from "@libs/auth/getTokens";
import { useCurriedLoaderData } from "@libs/router/hooks";
import { getApiClient } from "@libs/api/clients";
import { paths } from "utils/routing/paths";
import { SignOutPayload, useAuthChannelListeners } from "hooks/useAuthChannelListeners";
import { AdminRouterContext } from "router/types";
import { getAdminActivityStorage } from "storage/activity";
import { AdminAccountProvider } from "contexts/AdminAccountContext";
import { headers } from "utils/headers";

export const loader =
  ({ storage, baseUrl }: AdminRouterContext) =>
  async ({ request }: LoaderFunctionArgs) => {
    const url = new URL(request.url);
    const tokens = await getTokensForAuthCheck(storage.localStorage);
    const activityStorage = getAdminActivityStorage(storage.localStorage);
    const returnUrl = getFullUrl(url);

    if (activityStorage.isRecentlyActive()) {
      if (!tokens.identity) {
        return redirectDocument(paths.signIn({ returnUrl }));
      }

      const apiClient = getApiClient({
        baseUrl,
        headers,
        onRequestTokens: () => getTokensForApi(storage.localStorage),
      });
      const response = await apiClient.user.getUserSupportStatus();

      return {
        email: tokens.identity.email,
        firstName: tokens.identity.firstName,
        lastName: tokens.identity.lastName,
        ...response.data.data,
      };
    } else if (tokens.identity) {
      return redirect(paths.signOut({ returnUrl }));
    }

    return redirectDocument(paths.signIn({ returnUrl }));
  };

export const SignedInApp: FC = () => {
  const { ...rest } = useCurriedLoaderData<typeof loader>();

  const authChannelEvents = useMemo(
    () => ({
      onSignOut: (data: SignOutPayload) => {
        const params = {
          signOutReason: data.reason,
          ...(data.addReturnUrl ? { returnUrl: getFullUrl(window.location) } : undefined),
        };

        window.location.assign(paths.signIn(params));
      },
    }),
    []
  );

  useAuthChannelListeners(authChannelEvents);

  return <AdminAccountProvider {...rest} />;
};
