import {
  PracticeMigrationSettingsRequest,
  PracticeVO,
  UpdatePracticeFeatureState,
  UpdatePracticeTaxIdRequest,
} from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";

export const updatePractice = makeMutation({
  mutationKey: ["practices", "updatePractice"],
  formatParams: (args: { practiceId: number; data: PracticeVO }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId }) => {
      queryClient.setQueryData([getQueryKey("practices", "getPractice"), { practiceId }], data);
      queryClient.invalidateQueries([getQueryKey("user", "listPractices")]);
    },
  }),
});

export const batchUpdatePracticeFeatureState = makeMutation({
  mutationKey: ["practices", "batchUpdatePracticeFeatureState"],
  formatParams: (args: { practiceId: number; data: UpdatePracticeFeatureState[] }) => [
    args.practiceId,
    {
      updatePracticeFeatureStates: args.data,
    },
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getPracticeFeatureStates"), { practiceId }]);
    },
  }),
});

export const updatePracticeEin = makeMutation({
  mutationKey: ["practices", "updatePracticeEin"],
  formatParams: (args: { practiceId: number; data: UpdatePracticeTaxIdRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getPractice"), { practiceId }]);
    },
  }),
});

export const upsertPracticeMigrationSettings = makeMutation({
  mutationKey: ["practices", "upsertPracticeMigrationSettings"],
  formatParams: (args: { practiceId: number; data: PracticeMigrationSettingsRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPracticeMigrationSettings"),
        { practiceId },
      ]);
    },
  }),
});

export const createPractice = makeMutation({
  mutationKey: ["practices", "createPractice"],
  formatParams: (args: { data: PracticeVO }) => [args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries([getQueryKey("user", "listPractices")]);
    },
  }),
});
