import React, { FC, ReactNode, Suspense } from "react";
import { createPortal } from "react-dom";
import { SpringValue, useTransition, animated, easings } from "@react-spring/web";
import { cx } from "@libs/utils/cx";
import { usePortalElement } from "@libs/contexts/PortalContext";
import { ClickOutside } from "@libs/contexts/ClickOutsideListenerContext";
import { useDisableScrollBody } from "@libs/hooks/useDisableScrollBody";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { Backdrop } from "@libs/components/UI/Backdrop";

export const cxFlyoverWidth = {
  xs: "w-[24rem]",
  sm: "w-[28rem]",
  md: "w-[36rem]",
  lg: "w-[48rem]",
};

export type FlyoverProps = {
  onClickAway?: Func;
  children?: ReactNode;
  overlay?: ReactNode;
  width?: keyof typeof cxFlyoverWidth;
  dataTestId?: string;
};

const FlyoverContainer: React.FC<
  FlyoverProps & {
    styles: {
      opacity: SpringValue<number>;
      transform: SpringValue<string>;
    };
  }
> = ({ onClickAway, children, overlay, width = "sm", dataTestId, styles }) => {
  const currentPortal = usePortalElement();

  useDisableScrollBody();

  return (
    <ClickOutside>
      {(tracker) =>
        currentPortal
          ? createPortal(
              <div
                {...tracker}
                className={`
                  absolute
                  inset-0
                  pointer-events-none
                  *:pointer-events-auto
                  text-greyDark
                  z-10
                `}
              >
                {overlay ?? (
                  <animated.button
                    style={{ opacity: styles.opacity }}
                    type="button"
                    onClick={onClickAway}
                    className="absolute inset-0"
                  >
                    <Backdrop />
                  </animated.button>
                )}
                <animated.div
                  style={{ transform: styles.transform }}
                  className={cx(
                    `absolute
                     top-0
                     right-0
                     bottom-0
                     overflow-hidden
                     shadow-[0px_0px_24px_0px_#0000003D]
                     flex
                     flex-col
                     bg-white
                     h-full
                     text-left`,

                    cxFlyoverWidth[width]
                  )}
                  data-testid={dataTestId}
                >
                  <Suspense fallback={<LoadingContent />}>{children}</Suspense>
                </animated.div>
              </div>,
              currentPortal
            )
          : null
      }
    </ClickOutside>
  );
};

export const FlyoverV2: FC<FlyoverProps & { isOpen: boolean }> = ({ isOpen, ...props }) => {
  const transition = useTransition(isOpen, {
    from: { opacity: 0, transform: "translateX(100%)" },
    enter: { opacity: 1, transform: "translateX(0%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
    config: {
      duration: 300,
      easing: easings.easeOutExpo,
    },
  });

  return transition((style, item) => (item ? <FlyoverContainer {...props} styles={style} /> : null));
};
