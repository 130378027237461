import { ComponentProps } from "react";
import { cx } from "@libs/utils/cx";
import { isDefined } from "@libs/utils/types";
import { formatFullNameToInitials } from "@libs/utils/formatString";
import { isOneOf } from "@libs/utils/isOneOf";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as UserIcon } from "@libs/assets/icons/user.svg";
import { CharacterIcon, Props as CharacterIconProps } from "@libs/components/UI/CharacterIcon";

type Sizes = Exclude<ComponentProps<typeof CharacterIcon>["size"], "xs" | "2xs">;

export const cxThemes = {
  avatar: "flex items-center justify-center bg-greyLightest",
  image: "object-contain w-full h-full",
  initials: "font-sansSemiBold",
};

const cxBadgeStyles: Record<Sizes, string> = {
  sm: "h-2 w-2 bottom-0 right-0",
  md: "h-2 w-2 bottom-0 right-0",
  lg: "h-3 w-3 bottom-0 right-0",
  xl: "h-3 w-3 bottom-0.5 right-0.5",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "2xl": "h-3 w-3 bottom-1 right-1",
};

type CxBadgeColors = "bg-red-500" | "bg-green-500";

const cxStyles = {
  content: "rounded-full",
};

type Props = Pick<CharacterIconProps, "customTheme" | "theme" | "borderThickness"> & {
  badgeColor?: CxBadgeColors;
  badgeContent?: string;
  borderColor?: CharacterIconProps["border"];
  imageUrl?: string;
  isHipaaView?: boolean;
  name?: string;
  size?: Sizes;
};

export const Avatar: React.FC<Props> = ({
  badgeContent,
  badgeColor,
  theme,
  customTheme,
  borderColor,
  borderThickness,
  imageUrl,
  isHipaaView = false,
  name,
  size = "md",
}) => {
  return (
    <FloatingTooltip content={name ?? ""} theme="SMALL">
      <div className="relative">
        <CharacterIcon
          size={size}
          border={borderColor}
          borderThickness={borderThickness}
          theme={theme ?? ("slate-200" as const)}
          customTheme={customTheme}
        >
          {imageUrl && !isHipaaView ? (
            <img
              alt={name ?? imageUrl}
              className={cx(cxStyles.content, cxThemes.image)}
              src={imageUrl.startsWith("http") ? imageUrl : `data:image;base64,${imageUrl}`}
            />
          ) : name ? (
            <div className={cx(cxStyles.content, cxThemes.initials)}>
              {formatFullNameToInitials({ fullName: name })}
            </div>
          ) : (
            <div className={cx(cxStyles.content, cxThemes.avatar)}>
              {/* Uses more sizes than icons will support */}
              <UserIcon className="text-grey-700" />
            </div>
          )}
          {isDefined(badgeContent) && (
            <div
              className={cx(
                `flex
                 items-center
                 justify-center
                 absolute
                 text-grey-50
                 text-xxs
                 rounded-full`,
                badgeColor,
                cxBadgeStyles[size]
              )}
            >
              <span className={cx(isOneOf(size, ["sm", "md"]) && "hidden")}>{badgeContent}</span>
            </div>
          )}
        </CharacterIcon>
      </div>
    </FloatingTooltip>
  );
};
