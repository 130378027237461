import React from "react";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { getInfiniteEmployees } from "@libs/api/employee/queries";
import { PAGE_SIZE } from "@libs/utils/constants";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as PlusCircleIcon } from "@libs/assets/icons/plus-circle.svg";
import { Button } from "@libs/components/UI/Button";
import {
  HeaderCell,
  InputsHeaderRow,
  TableGrid,
  Title,
  TitleContainer,
} from "@libs/components/UI/GridTableComponents";
import { ScrollableInfiniteQuery } from "@libs/components/UI/ScrollableInfiniteQuery";
import { useBoolean } from "@libs/hooks/useBoolean";
import { FlyoverV2 } from "@libs/components/UI/FlyoverV2";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { AdminPanel } from "components/UI/AdminPanel";
import { usePathParams } from "hooks/usePathParams";
import { EmployeeRow } from "components/Practice/EmployeesTab/EmployeeRow";
import { CreateAdminFlyoverForm } from "components/Practice/EmployeesTab/CreateAdminFlyoverForm";

const COLUMNS = [
  {
    title: "User",
    width: "1fr",
  },

  {
    title: "Status",
    width: "6rem",
  },
  {
    title: "Owner",
    width: "5rem",
  },
  {
    title: "",
    width: "3rem",
  },
];

export const EmployeesTabRoute: React.FC = () => {
  const { practiceId } = usePathParams("practice");
  const addAdminFlyover = useBoolean(false);
  const employeesQuery = useInfiniteApiQuery(
    getInfiniteEmployees({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        statuses: ["PENDING", "ACTIVE"],
        isAdmin: true,
      },
    })
  );
  const adminEmployees = useFlattenPages(employeesQuery.data) ?? [];

  return (
    <>
      <InputsHeaderRow className="flex justify-between">
        <TitleContainer className="flex flex-row justify-between">
          <Title>Employees</Title>
        </TitleContainer>
      </InputsHeaderRow>
      <div className="flex flex-col h-full overflow-y-auto">
        <AdminPanel>
          <h2 className="text-sm font-sansSemiBold">Administrators</h2>

          <div className="flex flex-col gap-3">
            <ScrollableInfiniteQuery id="employees-table" infiniteQuery={employeesQuery}>
              {(employees) => (
                <TableGrid
                  columnWidths={COLUMNS.map((item) => item.width)}
                  className="rounded border border-greyLightest border-b-0 min-w-0"
                >
                  {COLUMNS.map((column, index) => (
                    <HeaderCell size="short" key={index} className="font-sansSemiBold" bgColor="bg-none">
                      {column.title}
                    </HeaderCell>
                  ))}
                  {employees.length > 0 ? (
                    employees.map((employee) => {
                      return <EmployeeRow key={employee.id} employee={employee} practiceId={practiceId} />;
                    })
                  ) : (
                    <div
                      className={`
                        flex
                        flex-col
                        items-center
                        justify-center
                        gap-3
                        h-48
                        col-span-full
                        border-b-slate-200
                        border-b
                        rounded-b-md
                      `}
                    >
                      <div className="text-slate-500">No admins exist yet for this practice.</div>
                      <Button onClick={addAdminFlyover.on} theme="link">
                        Invite an Admin
                      </Button>
                    </div>
                  )}
                </TableGrid>
              )}
            </ScrollableInfiniteQuery>
            {adminEmployees.length > 0 && (
              <Button
                className="flex items-center gap-x-2 text-xs mt-1"
                onClick={addAdminFlyover.on}
                theme="link"
              >
                <Icon SvgIcon={PlusCircleIcon} />
                Administrator
              </Button>
            )}
          </div>
        </AdminPanel>
      </div>
      <FlyoverV2 isOpen={addAdminFlyover.isOn} onClickAway={addAdminFlyover.off}>
        <CreateAdminFlyoverForm
          adminEmployees={adminEmployees}
          onClose={addAdminFlyover.off}
          practiceId={practiceId}
        />
      </FlyoverV2>
    </>
  );
};
