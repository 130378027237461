import { getNextPageParam, makeInfiniteQuery, makeQuery, makeQueryOptionalArgs } from "@libs/utils/queries";
import { fiveMinuteCache } from "@libs/utils/queryCache";

// For support user only
export const listPractices = makeInfiniteQuery({
  queryKey: ["user", "listPractices"],
  formatParams: (query: {
    searchString: string;
    maxPageSize?: number;
    pageNumber: number;
    pageSize: number;
  }) => [query],
  queryOptions: {
    ...fiveMinuteCache,
    getNextPageParam,
  },
});

export const searchForPractices = makeQuery({
  queryKey: ["user", "listPractices"],
  formatParams: (query: {
    searchString: string;
    maxPageSize?: number;
    pageNumber: number;
    pageSize: number;
  }) => [query],
});

export const getUserSupportStatus = makeQueryOptionalArgs({
  queryKey: ["user", "getUserSupportStatus"],
  formatParams: () => [],
});
