type ScrollPositionItem = {
  scrollLeft: number;
  scrollTop: number;
};

const scrollingStorage: Record<string, ScrollPositionItem | undefined> = {};

export const getScrollPosition = (id: string) => {
  return scrollingStorage[id];
};

export const setScrollPosition = (id: string, scrollLeft: number, scrollTop: number) => {
  scrollingStorage[id] = { scrollLeft, scrollTop };
};
