import { isOneOf } from "@libs/utils/isOneOf";

export const META_KEY = window.navigator.userAgent.includes("Mac") ? "⌘" : "Ctrl";

export const NON_TYPEABLE_INPUT_TYPES = [
  "button",
  "checkbox",
  "color",
  "file",
  "hidden",
  "image",
  "radio",
  "range",
  "reset",
  "submit",
];

export const TYPEABLE_INPUT_TYPES = [
  "date",
  "datetime-local",
  "email",
  "month",
  "number",
  "password",
  "search",
  "tel",
  "text",
  "time",
  "url",
  "week",
];

export const isTypeableElement = (eventTarget: Event["target"]) => {
  return (
    (eventTarget instanceof HTMLInputElement && isOneOf(eventTarget.type, TYPEABLE_INPUT_TYPES)) ||
    eventTarget instanceof HTMLTextAreaElement ||
    (eventTarget instanceof HTMLElement && eventTarget.attributes.getNamedItem("role")?.value === "textbox")
  );
};
