import { FC } from "react";
import {
  AddressSelection,
  FormFieldAddressAutocomplete,
} from "@libs/components/UI/FormFieldAddressAutocomplete";
import { FormFieldSelect } from "@libs/components/UI/FormFieldSelect";
import { AddressVO } from "@libs/api/generated-api";
import { AddressInputValidation } from "@libs/utils/address";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { stateOptions } from "@libs/components/UI/stateOptions";

interface Props {
  address: AddressVO;
  apiKey: string;
  validation?: AddressInputValidation;
  onChange: (data: AddressVO) => void;
}

export const AddressInput: FC<Props> = ({ validation, address, onChange, apiKey }) => {
  return (
    <>
      <div className="col-span-8">
        <div>
          <FormFieldAddressAutocomplete
            apiKey={apiKey}
            label="Address Location"
            defaultValue={address.address1}
            error={validation?.address1.$error}
            required
            onSelect={(selectedAddress: AddressSelection) => {
              const { address: address1, locality: city, state, zip, country } = selectedAddress;
              const selected = {
                address1,
                // whenever a new address is selected, clear the address2 field
                address2: "",
                city,
                state,
                zip,
                country,
              };

              onChange(selected);
            }}
          />
        </div>
        <p className="text-xs text-greyMedium mt-1.5">
          The State, City and Postal Code is auto-filled after selecting a location from the list.
        </p>
      </div>
      <div className="col-span-8">
        <FormFieldInput
          label="Address - Apartment, Unit, Suite, or Floor #"
          value={address.address2}
          onChange={(e) => {
            onChange({
              ...address,
              address2: e.target.value,
            });
          }}
          disabled={!address}
        />
      </div>
      <div className="col-span-8 grid grid-cols-3 gap-3 mb-6">
        <div className="col-span-1">
          <FormFieldInput
            label="City"
            value={address.city}
            error={validation?.city.$error}
            required
            onChange={(e) => {
              onChange({
                ...address,
                city: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-span-1">
          <FormFieldSelect
            label="State"
            value={address.state}
            error={validation?.state.$error}
            required
            onItemSelected={(state) => {
              onChange({
                ...address,
                state,
              });
            }}
            options={stateOptions}
          />
        </div>
        <div className="col-span-1">
          <FormFieldInput
            label="Postal Code"
            value={address.zip}
            error={validation?.zip.$error}
            required
            onChange={(e) => {
              onChange({
                ...address,
                zip: e.target.value,
              });
            }}
          />
        </div>
      </div>
    </>
  );
};
