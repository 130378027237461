import { ChangeEvent, forwardRef, InputHTMLAttributes, MutableRefObject, useMemo, useRef } from "react";
import { isString } from "@libs/utils/types";
import { round } from "@libs/utils/math";
import { leadingZerosRegex } from "@libs/utils/regex";
import { passRefs } from "@libs/utils/forms";
import { useFormattedInputString } from "@libs/hooks/useFormattedInputString";
import { split } from "@libs/utils/split";

export type NumberInputValue = number | null | undefined | string;
export type NumberInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "type" | "value" | "inputMode" | "step" | "min" | "max"
> & {
  value?: NumberInputValue;
  defaultValue?: NumberInputValue;
  min?: number;
  max?: number;
  step?: number;
  clamp?: boolean;
  onValueChange?: (
    value: number | undefined,
    values: {
      numberValue: number;
      stringValue: string;
      value?: number;
      formatted: string;
    }
  ) => void;
};

const usedKeys = new Set(["ArrowUp", "ArrowDown"]);

const countDecimals = (num: number) => {
  const [_, decimalDigits] = num.toString().split(".");

  if (decimalDigits) {
    return decimalDigits.length;
  }

  return 0;
};

const MAX_DECIMAL_POINTS = 1;
const cleanNumberString =
  ({
    decimalsLimit,
    clamp,
    min,
    max,
  }: {
    decimalsLimit: number;
    clamp?: boolean;
    min?: number;
    max?: number;
  }) =>
  (value: string) => {
    // only allow dash in first position
    let cleaned = value.trim();

    // deal with empty string
    if (!cleaned.length) {
      return cleaned;
    }

    // strip all dashes but first
    cleaned = cleaned.replaceAll(/[^^]-/g, "");

    const decimalSplit = split(cleaned, ".");

    // stip excess decimal points
    cleaned =
      decimalsLimit === 0
        ? // strip all decimal points
          decimalSplit[0]
        : // strip all but first decimal point
          decimalSplit.slice(0, MAX_DECIMAL_POINTS + 1).join(".");

    // strip all non numeric characters except remaining decimal point or dash
    cleaned = cleaned.replaceAll(/[^\d.-]/g, "");

    if (cleaned === "-") {
      if (clamp && min !== undefined && min >= 0) {
        return "";
      }

      return cleaned;
    }

    if (cleaned === "") {
      return cleaned;
    }

    // auto add zero for users
    if (cleaned === ".") {
      cleaned = `${0}.`;
    }

    if (cleaned === "-.") {
      cleaned = `-${0}.`;
    }

    // strip leading zeros
    const leadingZerosMatch = cleaned.match(leadingZerosRegex) as
      | (Omit<RegExpMatchArray, "groups"> & { groups: Record<string, string> })
      | null;

    cleaned = leadingZerosMatch
      ? `${leadingZerosMatch.groups.negative}${leadingZerosMatch.groups.rest}`
      : cleaned;

    if (decimalsLimit) {
      const regEx = new RegExp(`(?<include>[^.]*\\.\\d{${decimalsLimit}})(?<rest>.*)`);
      const match = cleaned.match(regEx);

      cleaned = match?.groups?.include ?? cleaned;
    }

    if (clamp) {
      const parsed = Number.parseFloat(cleaned);
      const clamped = clampNumber(parsed, { min, max });

      if (parsed !== clamped) {
        cleaned = String(clamped);
      }
    }

    return cleaned;
  };

const clampNumber = (val: number, { min, max }: { min?: number; max?: number }) => {
  if (min !== undefined && val < min) {
    return min;
  }

  if (max !== undefined && val > max) {
    return max;
  }

  return val;
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  maximumFractionDigits: 0,
});

export const formatNumber = (val: string) => {
  if (!val || val === "-") {
    return val;
  }

  const [integer, decimal] = split(val, ".");

  // for example, can be .3, -.3 or 3,000
  let formatted = !integer || integer === "-" ? integer : formatter.format(Number(integer));

  if (isString(decimal)) {
    formatted += `.${decimal}`;
  }

  return formatted;
};

// This adds a layer between the parent component state
// that is managed and useFormattedInputString to allow values
// being entered in the input that don't exactly match the parent
// components state, while allowing the parent to still update the
// NumberInput state if it's number type representation is different
// from the number type representation of the NumberInput
const throttlePropValueString = (value: NumberInputProps["value"], lastCleanedValue: string | undefined) => {
  let propStringValue = "";

  if (value || value === 0) {
    propStringValue = value === 0 ? (Object.is(0, value) ? "0" : "-0") : String(value);
  }

  // if there is something to compare against
  // try to see if we can re-use last cleaned value
  if (lastCleanedValue !== undefined) {
    const parsedProp = Number.parseFloat(propStringValue);
    const parsedClean = Number.parseFloat(lastCleanedValue);

    const hasParsedCleanedValueChanged =
      parsedProp !== parsedClean && !(Number.isNaN(parsedProp) && Number.isNaN(parsedClean));

    return hasParsedCleanedValueChanged ? propStringValue : lastCleanedValue;
  }

  return propStringValue;
};

const getNextValue = ({
  currentValue,
  key,
  step,
  decimalsLimit,
  clamp,
  min,
  max,
}: {
  currentValue: number;
  key: string;
  step: number;
  clamp: boolean | undefined;
  min: number | undefined;
  max: number | undefined;
  decimalsLimit: number;
}) => {
  let nextValue = 0;

  if (Number.isNaN(currentValue)) {
    nextValue = key === "ArrowDown" ? step * -1 : step;
  } else if (key === "ArrowUp") {
    const increment =
      round(currentValue < 0 ? Math.abs(currentValue % step) : step - (currentValue % step), decimalsLimit) ||
      step;

    nextValue = currentValue + increment;
  } else {
    const decrement =
      round(currentValue < 0 ? step - Math.abs(currentValue % step) : currentValue % step, decimalsLimit) ||
      step;

    nextValue = currentValue - decrement;
  }

  if (clamp) {
    nextValue = clampNumber(nextValue, { min, max });
  }

  return round(nextValue, decimalsLimit);
};

const formattedCharacters = [","];

const useFormattedNumber = ({
  value,
  min,
  max,
  clamp,
  step,
  decimalsLimit,
  elRef,
}: Pick<NumberInputProps, "value" | "min" | "max" | "clamp"> & {
  decimalsLimit: number;
  step: number;
  elRef: MutableRefObject<HTMLInputElement | null>;
}) => {
  const cleanedValueRef = useRef<string>();
  const cleanNumber = useMemo(() => {
    return cleanNumberString({
      decimalsLimit,
      clamp,
      min,
      max,
    });
  }, [decimalsLimit, clamp, min, max]);

  const formattedInput = useFormattedInputString(
    throttlePropValueString(value, cleanedValueRef.current),
    elRef,
    formattedCharacters,
    formatNumber,
    cleanNumber
  );

  if (cleanedValueRef.current === undefined) {
    cleanedValueRef.current = formattedInput.cleanedValue;
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { cleanedValue, formattedValue } = formattedInput.handleOnChange(e);

    cleanedValueRef.current = cleanedValue;

    if (cleanedValue === "" || cleanedValue === "-") {
      return {
        numberValue: Number.NaN,
        stringValue: cleanedValue,
        value: undefined,
        formatted: formattedValue,
      };
    }

    const numberValue = Number.parseFloat(cleanedValue);

    return {
      numberValue,
      stringValue: cleanedValue,
      value: numberValue,
      formatted: formattedValue,
    };
  };

  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;

    formattedInput.handleKeydown(e);

    if (step && usedKeys.has(key)) {
      e.preventDefault();

      const cleaned = cleanNumber(formattedInput.formattedValue);
      const numberValue = Number.parseFloat(cleaned);

      const nextValue = getNextValue({
        currentValue: numberValue,
        key,
        step,
        decimalsLimit,
        min,
        max,
        clamp,
      });

      const newValue = formattedInput.setValue(String(nextValue));

      cleanedValueRef.current = newValue.cleanedValue;

      return {
        numberValue: nextValue,
        stringValue: newValue.cleanedValue,
        value: nextValue,
        formatted: newValue.formattedValue,
      };
    }

    return undefined;
  };

  return {
    ...formattedInput,
    handleOnChange,
    handleKeydown,
  };
};

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  ({ value, onChange, onValueChange, onKeyDown, clamp, step = 1, min, max, ...rest }, ref) => {
    const decimalsLimit = useMemo(() => countDecimals(step), [step]);

    const elRef = useRef<HTMLInputElement | null>(null);
    const formattedInput = useFormattedNumber({
      elRef,
      value,
      min,
      max,
      step,
      clamp,
      decimalsLimit,
    });

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);

      const newValues = formattedInput.handleOnChange(e);

      onValueChange?.(newValues.value, newValues);
    };

    const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      onKeyDown?.(e);

      const newValues = formattedInput.handleKeydown(e);

      if (newValues && onValueChange) {
        onValueChange(newValues.value, newValues);
      }
    };

    const refs: (current: HTMLInputElement) => void = useMemo(() => passRefs([ref, elRef]), [ref]);

    return (
      <input
        onChange={handleOnChange}
        inputMode={decimalsLimit === 0 ? "numeric" : "decimal"}
        ref={refs}
        value={formattedInput.formattedValue}
        type="text"
        onKeyDown={handleKeydown}
        {...rest}
      />
    );
  }
);
