import { FC, useEffect, useState } from "react";
import NoInternet from "@libs/assets/images/no-internet.svg";

export const NoInternetConnection: FC = () => {
  const [isOnline, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    const online = () => setOnline(true);
    const offline = () => setOnline(false);

    window.addEventListener("online", online);
    window.addEventListener("offline", offline);

    return () => {
      window.removeEventListener("online", online);
      window.removeEventListener("offline", offline);
    };
  }, []);

  return isOnline ? null : (
    <div
      role="dialog"
      className={`
        flex
        flex-col
        items-center
        justify-center
        absolute
        inset-0
        z-30
        gap-10
        bg-white
      `}
    >
      <div className="flex flex-col items-center justify-center gap-4">
        <img alt="No Internet Connection" className="h-[466px] w-[422px]" src={NoInternet} />
        <div className="text-primaryTheme font-sansSemiBold text-6xl mt-8">Oops!</div>
        <div className="text-primaryTheme font-sansSemiBold text-4xl">No Internet Connection</div>
      </div>
    </div>
  );
};
