import React from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { InputsHeaderRow, Title, TitleContainer } from "@libs/components/UI/GridTableComponents";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { getPracticeDataQuery } from "api/practice/queries";
import { PracticeInfoForm } from "components/Practice/PracticeInfo/PracticeInfoForm";
import { usePathParams } from "hooks/usePathParams";
import { useEditPracticeSettings } from "components/Practice/hooks/useEditPracticeSettings";

export const PracticeInfoRoute: React.FC = () => {
  const { practiceId } = usePathParams("practice");
  const [practiceQuery] = useApiQueries([getPracticeDataQuery({ args: { practiceId } })]);
  const { handleEditingChanged, isEditing } = useEditPracticeSettings();

  return (
    <>
      <InputsHeaderRow className="flex justify-between">
        <TitleContainer className="flex flex-none items-center gap-x-1">
          <Title>Information</Title>
        </TitleContainer>
        {!isEditing && (
          <ButtonIcon SvgIcon={EditIcon} theme="primary" onClick={() => handleEditingChanged(true)} />
        )}
      </InputsHeaderRow>
      <QueryResult queries={[practiceQuery]}>
        {practiceQuery.data && (
          <PracticeInfoForm
            key={`${isEditing}`}
            practice={practiceQuery.data}
            edit={isEditing}
            onCloseEditor={() => handleEditingChanged(false)}
          />
        )}
      </QueryResult>
    </>
  );
};
