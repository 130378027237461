import { AdjustMethod, decimalAdjust } from "@libs/utils/math";

const DEFAULT_DECIMALS = 2;

type FormatNumberWithDecimalPlaces = { decimalPlaces?: number; adjustMethod?: AdjustMethod };

export const formatNumberWithDecimalPlaces = (num: number, options?: FormatNumberWithDecimalPlaces) => {
  const settings: Required<FormatNumberWithDecimalPlaces> = {
    adjustMethod: "round",
    decimalPlaces: DEFAULT_DECIMALS,
    ...options,
  };

  const adjusted = decimalAdjust(settings.adjustMethod, num, settings.decimalPlaces);

  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: settings.decimalPlaces,
    maximumFractionDigits: settings.decimalPlaces,
  }).format(adjusted);
};

export const formatNumberWithCommas = (num: number) => new Intl.NumberFormat("en-US").format(num);
