import { SignOutReasonVal } from "@libs/router/signOutReasons";
import { NumVal, routesConfigHelper, StrVal } from "@libs/router/url";

export const AuthRoutesConfig = routesConfigHelper({
  signIn: {
    path: "/auth/sign-in",
    query: {
      returnUrl: StrVal,
      signOutReason: SignOutReasonVal,
    },
  },
  forgotPassword: {
    path: "/auth/forgot-password",
    query: {
      returnUrl: StrVal,
      lastUserId: NumVal,
    },
  },

  signOut: {
    path: "/sign-out",
    query: {
      returnUrl: StrVal,
      signOutReason: SignOutReasonVal,
      to: StrVal,
    },
  },
  postSignOut: {
    path: "/post-sign-out",
  },
});
