import { captureMessage as sentryCaptureMessage } from "@sentry/react";

type CodeError = { message: string; code: string | number };
export const isCodeError = (error: unknown): error is CodeError => {
  return Boolean(error && typeof error === "object" && "message" in error && "code" in error);
};

export const handleCodeError = (error: CodeError) => {
  sentryCaptureMessage(`${error.code}: ${error.message}`, {
    level: "error",
  });
};
