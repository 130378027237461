import { FC, PropsWithChildren, ReactNode } from "react";
import { UseInfiniteScrollHookRefCallback } from "react-infinite-scroll-hook";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { getIsLoadingMore } from "@libs/utils/queries";
import {
  BaseApiEntryResult,
  UseInfiniteApiListQueryResult,
  UseInfiniteApiQueryResult,
} from "@libs/@types/apiQueries";
import { SafelyRenderedContent } from "@libs/components/UI/SafelyRenderedContent";
import { LoadingContent } from "@libs/components/UI/LoadingContent";

export interface ScrollableInfiniteQueryResultProps extends PropsWithChildren {
  infiniteQuery: UseInfiniteApiListQueryResult | UseInfiniteApiQueryResult<BaseApiEntryResult>;

  // Custom content to display to indicate more data is being fetched
  loadMore?: ReactNode;

  // Custom className for the `loadMore` container
  loadMoreClassName?: string;

  // Custom content to display before the first data loaded
  loading?: ReactNode;

  // Custom content to display when any data fails to load at all
  loadError?: ReactNode;

  // Custom content to display when loading more data fails
  loadMoreError?: ReactNode;

  // Custom content to display whne the list being scrolled through is empty
  noResults?: ReactNode;

  // A ref for tracking when to load more
  scrollRef: UseInfiniteScrollHookRefCallback;
}

export const ScrollableInfiniteQueryResult: FC<ScrollableInfiniteQueryResultProps> = ({
  infiniteQuery,
  children,
  loadError,
  loadMore,
  noResults,
  loadMoreError,
  loading,
  loadMoreClassName,
  scrollRef,
}) => {
  const firstPageData = infiniteQuery.data?.pages[0]?.data;
  const listData = Array.isArray(firstPageData) ? firstPageData : firstPageData?.entries;

  return (
    <>
      {infiniteQuery.isLoadingError ? (
        <SafelyRenderedContent fallback={<ErrorContent />}>{loadError}</SafelyRenderedContent>
      ) : // When the query is disabled it's not loading and doesn't have an error but the
      // typing thinks it must have data which isn't true

      infiniteQuery.isLoading || !listData ? (
        <SafelyRenderedContent fallback={<LoadingContent />}>{loading}</SafelyRenderedContent>
      ) : (!infiniteQuery.data.pages.length || !listData.length) && noResults ? (
        noResults
      ) : (
        <>
          {children}
          {infiniteQuery.isError ? (
            <SafelyRenderedContent fallback={<ErrorContent layout="horiz" />}>
              {loadMoreError}
            </SafelyRenderedContent>
          ) : getIsLoadingMore<unknown[] | BaseApiEntryResult>(infiniteQuery) ? (
            <div ref={scrollRef} className={loadMoreClassName}>
              <SafelyRenderedContent
                fallback={
                  <div className="h-6">
                    <LoadingContent />
                  </div>
                }
              >
                {loadMore}
              </SafelyRenderedContent>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};
