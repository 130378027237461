import { useCallback, useRef, Component, FC, PropsWithChildren, ReactNode, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { isParamParseError } from "@libs/router/url";

interface Props {
  notFoundContent: ReactNode;
  notFound: boolean;
  onParseParamsError: (error: TypeError) => void;
}

interface State {
  showNotFound: boolean;
}

class InnerNotFoundErrorBoundary extends Component<PropsWithChildren<Props>, State> {
  constructor(props: Props) {
    super(props);
    this.state = { showNotFound: props.notFound };
  }

  static getDerivedStateFromError(error: unknown) {
    return { showNotFound: isParamParseError(error) };
  }

  componentDidCatch(error: unknown) {
    if (!isParamParseError(error)) {
      throw error;
    }

    this.props.onParseParamsError(error);
  }
  componentDidUpdate(prevProps: Readonly<Props>) {
    if (!this.props.notFound && prevProps.notFound) {
      this.setState({ showNotFound: false });
    }
  }

  render() {
    if (this.state.showNotFound) {
      return this.props.notFoundContent;
    }

    return this.props.children;
  }
}

export const NotFoundErrorBoundary: FC<PropsWithChildren<Pick<Props, "notFoundContent">>> = ({
  children,
  notFoundContent,
}) => {
  const [paramsError, setParamsError] = useState<TypeError>();
  const paramsErrorRef = useRef(paramsError);
  const location = useLocation();
  const locationKeyRef = useRef(location.key);

  useEffect(() => {
    const didLocationChange = locationKeyRef.current !== location.key;

    locationKeyRef.current = location.key;

    if (paramsErrorRef.current && didLocationChange) {
      paramsErrorRef.current = undefined;
      setParamsError(undefined);
    }
  }, [location.key]);

  const handleParseParamsError = useCallback((error: TypeError) => {
    paramsErrorRef.current = error;
    setParamsError(error);
  }, []);

  return (
    <InnerNotFoundErrorBoundary
      notFound={Boolean(paramsError)}
      onParseParamsError={handleParseParamsError}
      notFoundContent={notFoundContent}
    >
      {children}
    </InnerNotFoundErrorBoundary>
  );
};
