import { getTokensForAuthCheck } from "@libs/auth/getTokens";
import { parseQueryParams } from "@libs/router/url";
import { redirectDocument, redirect, LoaderFunctionArgs } from "react-router-dom";
import { AdminRouterContext } from "router/types";
import { getAdminActivityStorage } from "storage/activity";
import { routesConfig, getRedirectToSignedInUrl, paths } from "utils/routing/paths";

export const authLoader =
  ({ storage }: AdminRouterContext) =>
  async ({ request }: LoaderFunctionArgs) => {
    const url = new URL(request.url);
    const { parsed: query } = parseQueryParams(routesConfig.signIn.query, url.searchParams);
    const tokens = await getTokensForAuthCheck(storage.localStorage);
    const activityStorage = getAdminActivityStorage(storage.localStorage);

    if (activityStorage.isRecentlyActive()) {
      if (tokens.account) {
        return redirectDocument(getRedirectToSignedInUrl(query.returnUrl));
      }
    } else if (tokens.identity) {
      return redirect(paths.signOut());
    }

    return null;
  };
