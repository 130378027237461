import { useRef, HTMLAttributes, forwardRef, useLayoutEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { passRefs } from "@libs/utils/forms";
import { getFullUrl } from "@libs/utils/location";
import { getScrollPosition, setScrollPosition } from "@libs/storage/scrolling";

export interface PersistScrollProps {
  id: string;
  // use if the you need to scroll back to the top. e.g. the scrollable content changes
  // if not passed the id will be used
  resetScrollKey?: unknown;
  disabled?: boolean;
  getScrollId?: (props: { id: string; resetScrollKey?: unknown }) => string;
}

/*
  When a component is unmounted we store the scroll position with a key that is
  a combination of the id and optionally the url.
*/

export const PersistScrollPosition = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & PersistScrollProps
>(({ id, resetScrollKey, disabled, getScrollId, ...rest }, ref) => {
  const location = useLocation();
  const hasMountedRef = useRef(false);
  const lastScrollIdRef = useRef("");

  const localRef = useRef<HTMLDivElement | null>(null);

  const scrollKey = resetScrollKey ?? id;
  const lastScrollKeyRef = useRef(scrollKey);

  const refs: (current: HTMLDivElement) => void = useMemo(() => passRefs([ref, localRef]), [ref]);

  const scrollId = useMemo(() => {
    if (getScrollId) {
      return getScrollId({ resetScrollKey, id });
    }

    const fullUrl = getFullUrl(location);

    return `${id}-${fullUrl}`;
  }, [id, resetScrollKey, getScrollId, location]);

  // on mount check to see if there is a matching saved scroll position

  // on resetScroll change scroll the element to the top. useful
  // for when the contents of scrollable area change but the component
  // is not re-mounted
  useLayoutEffect(() => {
    const el = localRef.current;

    // hasMountRef makes sure scroll position is only restored
    // once during the lifetime of the compoennt
    const isMounting = !hasMountedRef.current;

    hasMountedRef.current = true;

    lastScrollIdRef.current = scrollId;

    if (el && !disabled) {
      if (isMounting) {
        const scrollPosition = getScrollPosition(scrollId);

        if (scrollPosition) {
          el.scrollTo({ top: scrollPosition.scrollTop, left: scrollPosition.scrollLeft });
        }
      } else if (scrollKey !== lastScrollKeyRef.current) {
        el.scrollTo({ top: 0, left: 0 });
        lastScrollKeyRef.current = scrollKey;
      }
    }
  }, [scrollId, scrollKey, disabled]);

  // before unmount save the scroll position
  useLayoutEffect(() => {
    const el = localRef.current;

    return () => {
      if (el && !disabled) {
        setScrollPosition(lastScrollIdRef.current, el.scrollLeft, el.scrollTop);
      }
    };
  }, [disabled]);

  return <div id={id} {...rest} ref={refs} />;
});
