import { Query, QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { add, isBefore, millisecondsToSeconds } from "date-fns";
import { ApiErrorResponse, ApiResponse } from "@libs/@types/api";

const refechWithIgnoringErrors = <T = unknown>(
  query: Query<ApiResponse<T>, ApiErrorResponse, ApiResponse<T>, QueryKey>,
  statusCodesToIgnore: Set<number>
) => {
  const errorStatus = query.state.error?.status;

  let shouldIgnore = Boolean(errorStatus && statusCodesToIgnore.has(errorStatus));

  if (shouldIgnore) {
    const expiry = add(new Date(query.state.errorUpdatedAt), {
      seconds: millisecondsToSeconds(query.cacheTime),
    });

    // Ignore if error is still within cache time
    shouldIgnore = isBefore(new Date(), expiry);
  }

  return !shouldIgnore;
};

/*
  In some cases, APIs may return an error status code for a valid state.  For example a 404 status may be returned before an entity has been created. React Query will automatically retry and refetch on window focus for these status codes.

  This function allows you to ignore these status codes, preventing users from seeing a flickering loading state each time they focus the window, due to the refetchOnWindowFocus setting.
*/
export const queryOptionsIgnoreErrors = <T = unknown>(statusCodesToIgnore: Set<number>) => {
  const options: UseQueryOptions<ApiResponse<T>, ApiErrorResponse, ApiResponse<T>, QueryKey> = {
    refetchOnMount: (query) => {
      return refechWithIgnoringErrors(query, statusCodesToIgnore);
    },
    refetchOnWindowFocus: (query) => {
      return refechWithIgnoringErrors(query, statusCodesToIgnore);
    },
  };

  return options;
};
