import { PracticeInfoVO } from "@libs/api/generated-api";
import { EMPTY_CELL, LinkCell, Row } from "@libs/components/UI/GridTableComponents";
import { FC } from "react";
import { OnboardingStatusPill } from "components/UI/OnboardingStatusPill";
import { paths } from "utils/routing/paths";

export const PracticeRow: FC<{ practice: PracticeInfoVO }> = ({ practice }) => {
  const sharedProps = {
    to: paths.practice({ practiceId: practice.id, tab: "info" }),
  };

  return (
    <Row key={practice.id}>
      <LinkCell {...sharedProps}>{practice.id}</LinkCell>
      <LinkCell {...sharedProps}>{practice.name}</LinkCell>
      <LinkCell {...sharedProps}>
        <OnboardingStatusPill status={practice.onboardingStatus} />
      </LinkCell>
      <LinkCell {...sharedProps}>
        {(practice.ownerName?.length ?? 0) > 0 ? practice.ownerName : EMPTY_CELL}
      </LinkCell>
    </Row>
  );
};
