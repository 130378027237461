import { forwardRef } from "react";
import { Link, LinkProps } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { cxStyles } from "@libs/components/UI/ButtonIcon";
import { IconProps, Icon } from "@libs/components/UI/Icon";

export type LinkIconProps = LinkProps & IconProps & { iconGlyphClassName?: string };
export const LinkIcon = forwardRef<HTMLAnchorElement, LinkIconProps>(
  ({ SvgIcon, theme, size, iconClassName, iconGlyphClassName, tooltip, ...linkAttrs }, ref) => {
    return (
      <Link {...linkAttrs} className={cx(cxStyles.container(), linkAttrs.className)} ref={ref}>
        <Icon
          SvgIcon={SvgIcon}
          theme={theme}
          size={size}
          className={iconClassName}
          iconClassName={iconGlyphClassName}
          disabled={false}
          tooltip={tooltip}
        />
      </Link>
    );
  }
);
