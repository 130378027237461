import { PracticeVO } from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";

const businessTypes: NonNullable<PracticeVO["businessType"]>[] = [
  "LIMITED_LIABILITY_COMPANY",
  "CORPORATION",
  "SOLE_PROPRIETORSHIP",
  "NON_PROFIT",
  "PARTNERSHIP",
  "COOPERATIVE",
  "LIMITED_PARTNERSHIP",
];

export const BUSINESS_TYPE_OPTIONS = businessTypes.map((type) => ({
  label: sentenceCaseConstant(type),
  value: type,
}));
