import { FC } from "react";
import { NavIcon, Props as NavIconProps } from "@libs/components/UI/NavIcon";

type Props = Omit<NavIconProps, "selectedClassName" | "className" | "iconClassName"> & {
  selected?: boolean;
};

export const PracticeNavIcon: FC<Props> = ({ selected, ...rest }) => {
  return (
    <NavIcon {...rest} className={selected ? "bg-archyBlue-50" : "hover:bg-slate-200 active:bg-slate-100"} />
  );
};
