import { BatchCreateEmployeeAdministratorRequest, CreateEmployeeRequest } from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";

export const createEmployee = makeMutation({
  mutationKey: ["practices", "createEmployee"],
  formatParams: (args: { practiceId: number; data: CreateEmployeeRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getEmployees"), { practiceId }]);
    },
  }),
});

export const deleteEmployees = makeMutation({
  mutationKey: ["practices", "multiUpdate1"],
  formatParams: (args: { practiceId: number; action: "DELETE" | "ARCHIVE"; ids: number[] }) => [
    args.practiceId,
    { action: args.action },
    { ids: args.ids },
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getEmployees"), { practiceId }]);
    },
  }),
});

export const createPracticeAdministrators = makeMutation({
  mutationKey: ["admin", "createPracticeAdministrators"],
  formatParams: (args: { practiceId: number; data: BatchCreateEmployeeAdministratorRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getEmployees"), { practiceId }]);
    },
  }),
});
