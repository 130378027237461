import { addressSchema } from "@libs/utils/address";
import { email, phoneNumber, required, url } from "@libs/utils/validators";

const commonValidators = {
  firstName: [
    {
      $v: required,
      $error: "First name is required",
    },
  ],
  lastName: [
    {
      $v: required,
      $error: "Last name is required",
    },
  ],
  email: [
    {
      $v: required,
      $error: "Email is required",
    },
    {
      $v: email,
      $error: "Please enter a valid email",
    },
  ],
  phoneNumber: [
    {
      $v: required,
      $error: "Phone number is required",
    },
    {
      $v: phoneNumber,
      $error: "Phone number is invalid",
    },
  ],
  jobTitleKey: [
    {
      $v: required,
      $error: "Job type is required",
    },
  ],
  address: {
    address1: [
      {
        $v: required,
        $error: "Address is required",
      },
    ],
    city: [
      {
        $v: required,
        $error: "City is required",
      },
    ],
    state: [
      {
        $v: required,
        $error: "State is required",
      },
    ],
    zip: [
      {
        $v: required,
        $error: "Zip is required",
      },
    ],
  },
};

export const getPracticeOwnerSchema = ({ includeEmail }: { includeEmail?: boolean }) => {
  return {
    firstName: commonValidators.firstName,
    lastName: commonValidators.lastName,
    email: {
      $ignore: !includeEmail,
      $validations: commonValidators.email,
    },
    phoneNumber: commonValidators.phoneNumber,
    jobTitleKey: commonValidators.jobTitleKey,
  };
};

export const getPersonalInfoSchema = ({ isOwner, isAdmin }: { isOwner?: boolean; isAdmin?: boolean }) => {
  return {
    firstName: commonValidators.firstName,
    lastName: commonValidators.lastName,
    dob: [
      {
        $v: required,
        $error: "Date of birth is required",
        $ignore: !isOwner,
      },
    ],
    phoneNumber: commonValidators.phoneNumber,
    jobTitleKey: {
      $validations: commonValidators.jobTitleKey,
      $ignore: !isAdmin,
    },
  };
};

export const getPracticeInfoSchema = () => {
  return {
    contactDetails: {
      phoneNumber: commonValidators.phoneNumber,
      email: commonValidators.email,
    },
    website: [
      {
        $v: required,
        $error: "Website is required",
      },
      {
        $v: url,
        $error: "Website is invalid",
      },
    ],
    physicalAddress: addressSchema,
  };
};
