import { FormFieldFormattedInput } from "@libs/components/UI/FormFieldFormattedInput";
import { FormFieldInputProps } from "@libs/components/UI/FormFieldInput";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import { TAX_ID_LENGTH, taxIdFormatter, taxIdParser } from "@libs/utils/taxId";

export const FormFieldTaxId: React.FC<
  { taxIdLastFour?: string; onValueChange: (value: string) => void } & Omit<
    FormFieldInputProps,
    "onChange" | "maxLength"
  >
> = ({ taxIdLastFour, edit = true, onValueChange, value, ...rest }) => {
  const taxIdPlaceholder = taxIdLastFour ? `*****${taxIdLastFour}` : "";

  return (
    <FormFieldFormattedInput
      autoComplete="false"
      formatReadOnlyValue={() => taxIdPlaceholder || EMPTY_CELL}
      placeholder={taxIdLastFour ? taxIdPlaceholder : "__-_______"}
      edit={edit}
      value={edit ? value : taxIdPlaceholder}
      // Add one for the dash
      maxLength={TAX_ID_LENGTH + 1}
      formatValue={taxIdFormatter}
      cleanValue={taxIdParser}
      // Since taxId is never returned we can only tell if a user has saved a taxId
      // by checking if taxIdLastFour exists. If it does, the field, taxId, is not required.
      // Otherwise, the user must enter a taxId.
      required={!taxIdLastFour}
      onValueChange={onValueChange}
      {...rest}
    />
  );
};
