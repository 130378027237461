import { FC } from "react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { Outlet } from "react-router-dom";
import { AppLayout } from "components/Main/AppLayout";
import { Navigation } from "components/Main/Navigation";
import { RecoverError } from "components/Main/RecoverError";
import { NotFoundErrorBoundary } from "components/Main/NotFoundErrorBoundary";
import { NotFoundRoute } from "components/NotFoundRoute";
import { AdminSegmentProvider } from "contexts/AdminSegmentContext";

export const AuthLayout: FC = () => {
  return (
    <AppLayout nav={<Navigation />}>
      <SentryErrorBoundary fallback={<RecoverError />}>
        <AdminSegmentProvider>
          <NotFoundErrorBoundary notFoundContent={<NotFoundRoute />}>
            <Outlet />
            {/* Max height should be 100vh less the height of the header + 12px padding on top + bottom */}
            <div
              className={`
                absolute
                bottom-3
                right-3
                flex
                flex-col
                max-h-[calc(100vh-82px)]
              `}
            />
          </NotFoundErrorBoundary>
        </AdminSegmentProvider>
      </SentryErrorBoundary>
    </AppLayout>
  );
};
