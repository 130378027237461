import { captureMessage as sentryCaptureMessage } from "@sentry/react";
import { Error, ErrorResponse } from "@libs/api/generated-api";
import { CANNED_ERROR_MESSAGE } from "@libs/utils/constants";
import { isDefined } from "@libs/utils/types";
import { isOneOf } from "@libs/utils/isOneOf";

const INDENTATION = 2;

export const isErrorResponse = (error: unknown): error is ErrorResponse => {
  return Boolean(
    error &&
      typeof error === "object" &&
      "traceId" in error &&
      "status" in error &&
      "errors" in error &&
      Array.isArray(error.errors)
  );
};

const ignoreFieldValidationMessage = new Set([
  "State changes are disallowed on completed appointments.",
  "Future appointments cannot be marked as completed.",
  "Completed appointments cannot be moved to a future date.",
  "Only claims in the pending state can be manually submitted.",
  "Only pending or failed claims can be updated.",
  "Appointment in its current state cannot be deleted.",
]);

const logValidationErrors = (
  traceId: string | undefined,
  status: string | undefined,
  errors: Error[],
  url?: string
) => {
  const filteredValidations = errors.filter((e) => e.message && !ignoreFieldValidationMessage.has(e.message));

  if (filteredValidations.length) {
    sentryCaptureMessage(
      filteredValidations
        .map((e) => e.message)
        .filter(isDefined)
        .join("\n"),
      {
        level: "warning",
        extra: {
          traceId,
          url,
          status,
          errors: JSON.stringify(filteredValidations, null, INDENTATION),
        },
      }
    );
  }
};

const logOtherErrors = (
  traceId: string | undefined,
  status: string | undefined,
  errors: Error[],
  url?: string
) => {
  sentryCaptureMessage(
    errors
      .map((e) => e.message)
      .filter(isDefined)
      .join("\n") || CANNED_ERROR_MESSAGE,
    {
      level: "error",
      extra: {
        traceId,
        url,
        status,
        errors: errors.length ? JSON.stringify(errors, null, INDENTATION) : undefined,
      },
    }
  );
};

export const handleErrorResponse = (error: ErrorResponse, url?: string) => {
  const badInputErrors: Error[] = [];
  const otherErrors: Error[] = [];

  const errors = error.errors ?? [];

  // separate validation errors from other errors
  for (const errorItem of errors) {
    if (errorItem.errorCode && isOneOf(errorItem.errorCode, ["FIELD_VALIDATION_ERROR", "BAD_REQUEST"])) {
      badInputErrors.push(errorItem);
    } else {
      otherErrors.push(errorItem);
    }
  }

  // log validation errors as warnings and filter out ones we know are not useful
  if (badInputErrors.length > 0) {
    logValidationErrors(error.traceId, error.status, badInputErrors, url);
  }

  // If there are other errors, log them as errors and if there are no errors
  // log a canned error message
  if (otherErrors.length > 0 || badInputErrors.length === 0) {
    logOtherErrors(error.traceId, error.status, otherErrors, url);
  }

  // show toast messages for all errors
  const errorMessages = errors.map((e) => e.message).filter(isDefined);

  if (errorMessages.length === 0) {
    errorMessages.push(CANNED_ERROR_MESSAGE);
  }

  return errorMessages;
};
