import { FC, MouseEventHandler, useCallback } from "react";
import { Link } from "react-router-dom";

import { PracticeInfoVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatPhoneNumber } from "@libs/utils/phone";
import { paths } from "utils/routing/paths";

interface Props {
  practice: PracticeInfoVO;
  onLinkToPractice: Func;
  onMouseEnterResult: MouseEventHandler<HTMLAnchorElement>;
  isHighlighted?: boolean;
  isRecent?: boolean;
  onClick?: (practice: PracticeInfoVO) => void;
}

export const PracticeRowContents: FC<Pick<Props, "practice">> = ({ practice }) => {
  const { name, ownerName, phoneNumber, addressDetails } = practice;

  return (
    <div className="flex items-center gap-x-3">
      <div className="flex flex-col">
        <span className="text-sm text-slate-900">{name}</span>
        <div
          className={`
            flex
            gap-x-2
            text-xs
            text-slate-700
            divide-x
            divide-slate-300
          `}
        >
          <span>{ownerName || "No Owner"}</span>
          {phoneNumber ? <span className="pl-2">{formatPhoneNumber(phoneNumber)}</span> : null}
          {addressDetails ? <span className="pl-2">{addressDetails.city}</span> : null}
        </div>
      </div>
    </div>
  );
};

const cxStyles = {
  container: (isHighlighted: boolean) => {
    return cx(
      "flex items-center justify-between rounded p-3 cursor-pointer",
      isHighlighted && "bg-slate-100"
    );
  },
};

export const SearchPracticesResult: FC<Props> = ({
  practice,
  onMouseEnterResult,
  onClick,
  isHighlighted = false,
  onLinkToPractice,
  ...rest
}) => {
  const handleClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      onLinkToPractice();

      if (onClick) {
        e.preventDefault();
        onClick(practice);
      }
    },
    [onClick, onLinkToPractice, practice]
  );

  return (
    <Link
      className={cxStyles.container(isHighlighted)}
      to={paths.practice({ practiceId: practice.id, tab: "info" })}
      onClick={handleClick}
      onMouseEnter={onMouseEnterResult}
    >
      <PracticeRowContents practice={practice} {...rest} />
    </Link>
  );
};
