import { PracticeMigrationSettingsVO } from "@libs/api/generated-api";
import { queryOptionsIgnoreErrors } from "@libs/api/utils/queryOptionsIgnoreErrors";
import { makeQuery } from "@libs/utils/queries";
import { fiveMinuteCache } from "@libs/utils/queryCache";
import { NOT_FOUND } from "@libs/utils/statusCodes";

export const getPracticeDataQuery = makeQuery({
  queryKey: ["practices", "getPractice"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: {
    ...fiveMinuteCache,
  },
});

export const getPracticeInfoById = makeQuery({
  queryKey: ["practices", "getPracticeInfoById"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: {
    ...fiveMinuteCache,
  },
});

export const getPracticeFeatureStates = makeQuery({
  queryKey: ["practices", "getPracticeFeatureStates"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: {
    ...fiveMinuteCache,
  },
});

export const getPracticeConnectedRemoteSettings = makeQuery({
  queryKey: ["practices", "getPracticeConnectedRemoteSettings"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: fiveMinuteCache,
});

export const getPracticeRemoteSettings = makeQuery({
  queryKey: ["practices", "getPracticeRemoteSettings"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: fiveMinuteCache,
});

export const getPracticeMigrationSettings = makeQuery({
  queryKey: ["practices", "getPracticeMigrationSettings"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: {
    ...fiveMinuteCache,
    ...queryOptionsIgnoreErrors<PracticeMigrationSettingsVO>(new Set([NOT_FOUND])),
  },
});
