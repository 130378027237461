import { FC } from "react";
import { InputsHeaderRow, Title, TitleContainer } from "@libs/components/UI/GridTableComponents";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { usePathParams } from "hooks/usePathParams";
import { getPracticeFeatureStates } from "api/practice/queries";
import { PracticeFeaturesForm } from "components/Practice/forms/PracticeFeaturesForm";
import { useEditPracticeSettings } from "components/Practice/hooks/useEditPracticeSettings";

export const PracticeFeaturesRoute: FC = () => {
  const { practiceId } = usePathParams("practice");
  const { handleEditingChanged, isEditing } = useEditPracticeSettings();

  const [practiceFeaturesQuery] = useApiQueries([getPracticeFeatureStates({ args: { practiceId } })]);

  return (
    <>
      <InputsHeaderRow className="flex justify-between">
        <TitleContainer className="flex flex-row justify-between">
          <Title>Features</Title>
        </TitleContainer>
        {!isEditing && (
          <ButtonIcon SvgIcon={EditIcon} theme="primary" onClick={() => handleEditingChanged(true)} />
        )}
      </InputsHeaderRow>
      <QueryResult queries={[practiceFeaturesQuery]}>
        {practiceFeaturesQuery.data && (
          <PracticeFeaturesForm
            features={practiceFeaturesQuery.data}
            isEditing={isEditing}
            onClickSecondaryButton={() => handleEditingChanged(false)}
            onSubmissionSuccess={() => handleEditingChanged(false)}
            practiceId={practiceId}
          >
            <p className="font-sansSemiBold text-sm">Features</p>
          </PracticeFeaturesForm>
        )}
      </QueryResult>
    </>
  );
};
