import { UserSupportStatusVO } from "@libs/api/generated-api";
import { createContext, FC, useContext, useMemo } from "react";
import { Outlet } from "react-router-dom";

export interface AdminAccountContextValue extends UserSupportStatusVO {
  firstName: string;
  lastName: string;
  email: string;
}

export const AdminAccountContext = createContext<AdminAccountContextValue>({
  firstName: "",
  lastName: "",
  email: "",
  isAdminPortalSuperuser: false,
  isArchySupportIdentity: false,
});

export const useAdminAccount = () => useContext(AdminAccountContext);

export const AdminAccountProvider: FC<AdminAccountContextValue> = ({
  firstName,
  lastName,
  email,
  isAdminPortalSuperuser,
  isArchySupportIdentity,
}) => {
  const account: AdminAccountContextValue = useMemo(() => {
    return {
      firstName,
      lastName,
      email,
      isAdminPortalSuperuser,
      isArchySupportIdentity,
    };
  }, [firstName, lastName, email, isAdminPortalSuperuser, isArchySupportIdentity]);

  return (
    <AdminAccountContext.Provider value={account}>
      <Outlet />
    </AdminAccountContext.Provider>
  );
};
