import React, { ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { passRefs } from "@libs/utils/forms";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { useInfiniteScrollQuery, UseInfiniteScrollQueryProps } from "@libs/hooks/useInfiniteScrollQuery";
import {
  ScrollableInfiniteQueryResultProps,
  ScrollableInfiniteQueryResult,
} from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { PersistScrollPosition, PersistScrollProps } from "@libs/components/UI/PersistScrollPosition";

type Props<T> = UseInfiniteScrollQueryProps &
  Omit<ScrollableInfiniteQueryResultProps, "children" | "infiniteQuery" | "scrollRef"> & {
    className?: string;

    getPersistScrollId?: PersistScrollProps["getScrollId"];

    // If you are rendering different lists, changing the query key will scroll the user back to the top to start
    // scrolling through the new list
    queryKey?: unknown;

    // Render props are a flattened list of all items to render
    children: (item: T[]) => ReactNode;

    infiniteQuery: UseInfiniteApiListQueryResult<T>;

    id: string;
    persistScroll?: boolean;
  };

const ScrollableInfiniteQueryWithRef = <T,>(
  {
    infiniteQuery,
    queryKey,
    children,
    loadError,
    loadMore,
    noResults,
    loadMoreError,
    loading,
    className,
    rootMargin,
    id,
    persistScroll = true,
    getPersistScrollId,
  }: Props<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery, rootMargin });

  const items = useFlattenPages(infiniteQuery.data);
  const refs: (current: HTMLDivElement) => void = React.useMemo(
    () => passRefs([rootRef, ref]),
    [ref, rootRef]
  );

  return (
    <PersistScrollPosition
      getScrollId={getPersistScrollId}
      resetScrollKey={queryKey}
      id={id}
      className={cx("h-full min-h-inherit overflow-y-auto", className)}
      ref={refs}
      disabled={!persistScroll}
    >
      <ScrollableInfiniteQueryResult
        loadError={loadError}
        loadMore={loadMore}
        noResults={noResults}
        loadMoreError={loadMoreError}
        loading={loading}
        infiniteQuery={infiniteQuery}
        scrollRef={scrollRef}
      >
        {items ? children(items) : null}
      </ScrollableInfiniteQueryResult>
    </PersistScrollPosition>
  );
};

export const ScrollableInfiniteQuery = React.forwardRef(ScrollableInfiniteQueryWithRef) as <T>(
  props: Props<T> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof ScrollableInfiniteQueryWithRef>;
