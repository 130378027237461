import React from "react";
import { useDebounce } from "use-debounce";
import { SEARCH_DEBOUNCE_DELAY_MS } from "@libs/utils/constants";

export const useDebouncedSearch = <T>(search: T) => {
  const lastSearch = React.useRef<Set<T>>(new Set([]));

  const [debouncedSearch, debounced] = useDebounce(search, SEARCH_DEBOUNCE_DELAY_MS, {
    leading: false,
    trailing: true,
  });

  React.useEffect(() => {
    lastSearch.current.add(debouncedSearch);
  }, [debouncedSearch]);

  const hasSearchedThisPrior = lastSearch.current.has(search);

  return {
    search: hasSearchedThisPrior ? search : debouncedSearch,
    isWaiting: hasSearchedThisPrior ? false : debounced.isPending(),
  };
};
