import { FC, PropsWithChildren } from "react";
import { FloatingTooltipProps } from "@libs/components/UI/FloatingTooltip";
import { Icon, IconProps } from "@libs/components/UI/Icon";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";

interface Props {
  SvgIcon?: IconComponent;
  tooltip: Omit<FloatingTooltipProps, "children">;
  theme?: IconProps["theme"];
  disabled?: boolean;
}

export const TooltipLabel: FC<PropsWithChildren<Props>> = ({
  children,
  SvgIcon = InfoIcon,
  tooltip,
  theme = "slate500",
  disabled = false,
}) => {
  return (
    <div className="flex items-center gap-x-1">
      {children}
      {tooltip.content ? (
        <Icon SvgIcon={SvgIcon} tooltip={tooltip} disabled={disabled} theme={theme} size="sm" />
      ) : null}
    </div>
  );
};
