import { TaskProgressHeader } from "@libs/components/UI/TaskProgressHeader";
import { useNavigate } from "react-router-dom";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { usePathParams } from "hooks/usePathParams";
import { getPracticeFeatureStates } from "api/practice/queries";
import { PracticeFeaturesForm } from "components/Practice/forms/PracticeFeaturesForm";
import { paths } from "utils/routing/paths";

export const SetupPracticeFeaturesRoute: React.FC = () => {
  const navigate = useNavigate();
  const { practiceId } = usePathParams("setupPracticeFeatures");

  const [practiceFeaturesQuery] = useApiQueries([getPracticeFeatureStates({ args: { practiceId } })]);

  return (
    <QueryResult queries={[practiceFeaturesQuery]}>
      {practiceFeaturesQuery.data && (
        <PracticeFeaturesForm
          features={practiceFeaturesQuery.data}
          isEditing
          onSubmissionSuccess={() => {
            navigate(paths.practice({ practiceId, tab: "info" }));
          }}
          primaryButtonText="Finish"
          practiceId={practiceId}
        >
          <TaskProgressHeader totalSteps={3} step={3} />
          <div className="flex flex-col gap-2">
            <h2 className="text-sm font-sansSemiBold">Features</h2>
            <p className="text-xs">Select the features you want to enable for this practice</p>
          </div>
        </PracticeFeaturesForm>
      )}
    </QueryResult>
  );
};
