import { stripAllButNumbers } from "@libs/utils/inputFormatting";

const TAX_ID_FORMAT = "XX-XXXXXXX";

export const TAX_ID_LENGTH = 9;

export const taxIdFormatter = (value?: string) => {
  if (!value) {
    return "";
  }

  const dashPosition = TAX_ID_FORMAT.indexOf("-");

  if (value.length > dashPosition) {
    return `${value.slice(0, dashPosition)}-${value.slice(dashPosition)}`;
  }

  return value;
};

export const taxIdParser = (value: string) => stripAllButNumbers(value).slice(0, TAX_ID_LENGTH);
