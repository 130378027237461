import { FC } from "react";
import { cx } from "@libs/utils/cx";
import { isString } from "@libs/utils/types";
import { FloatingTooltip, FloatingTooltipProps } from "@libs/components/UI/FloatingTooltip";
import { NotificationCountIndicator } from "@libs/components/UI/NotificationIndicator";

export type Props = {
  Icon: IconComponent;
  notificationCount?: number;
  tooltip?: Omit<FloatingTooltipProps, "children">;
  selectedClassName?: string;
  className?: string;
  iconClassName?: string;
};

export const NavIcon: FC<Props> = ({
  tooltip = { content: "" },
  notificationCount,
  Icon,
  className = "hover:bg-slate-200 active:bg-slate-100",
  iconClassName = "text-slate-700",
}) => {
  return (
    <FloatingTooltip {...tooltip}>
      <div
        className={cx(
          `w-8
           h-8
           rounded
           flex
           items-center
           justify-center
           transition-colors`,
          className
        )}
      >
        <NotificationCountIndicator count={notificationCount}>
          <Icon
            role="img"
            aria-label={isString(tooltip.content) ? tooltip.content : undefined}
            className={cx("w-6 h-6", iconClassName)}
          />
        </NotificationCountIndicator>
      </div>
    </FloatingTooltip>
  );
};
