import { FC, HTMLAttributes, ReactNode } from "react";
import { cx } from "@libs/utils/cx";

export const SettingsLabel: FC<{ className?: string; children?: ReactNode }> = ({ className, children }) => {
  return <div className={cx("text-xs text-greyDark", className)}>{children}</div>;
};

export const SettingsValue: FC<{ className?: string } & HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  return <div {...props} className={cx("text-xs text-greyDark font-sansSemiBold mt-1", className)} />;
};
